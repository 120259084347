<div class="modal-container" *ngIf="invite">
  <div class="modal-content">
    <img [src]="user.photo_url" alt="Personal Trainer" class="trainer-image" />
    <h2>{{ user.name }}</h2>
    <div class="modal-container">
      <div class="modal-content">
        <form [formGroup]="studentForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="name">Nome Completo</label>
            <input
              id="name"
              formControlName="name"
              type="text"
              placeholder="Nome"
              class="form-control"
            />
            <p
              *ngIf="studentForm.get('name')?.value == ''"
              style="font-size: 10px; color: red"
              class="ml-2"
            >
              *Insira seu nome completo.
            </p>
          </div>

          <div class="form-group">
            <label for="email">Email</label>
            <input
              id="email"
              formControlName="email"
              type="email"
              placeholder="exemplo@email.com"
              class="form-control"
              (keyup)="onEmailChange(studentForm.get('email')?.value)"
              autocomplete="off"
            />

            <p
              *ngIf="
                studentForm.get('email')?.value &&
                !studentForm.get('email')?.value.includes('@')
              "
              style="font-size: 10px; color: red"
              class="ml-2"
            >
              *Insira um email válido.
            </p>

            <ul
              *ngIf="showSuggestions && filteredProviders.length > 0"
              class="suggestions-list"
              style="
                z-index: 1000;
                background: white;
                border: 1px solid #ccc;
                list-style: none;
                padding: 0;
                margin: 0;
              "
            >
              <li
                *ngFor="let provider of filteredProviders; let i = index"
                (click)="selectSuggestion(provider)"
                [class.selected]="i === selectedIndex"
                style="padding: 5px 10px; cursor: pointer"
                class="suggestion-item"
              >
                {{
                  studentForm.get("email")?.value.split("@")[0] + "@" + provider
                }}
              </li>
            </ul>
          </div>

          <div class="form-group">
            <label for="phone">Telefone</label>
            <input
              id="phone"
              formControlName="phone"
              type="tel"
              placeholder="(99) 99999-9999"
              mask="(00) 00000-0000"
              class="form-control"
            />
            <p
              *ngIf="studentForm.get('phone')?.value?.length < 11"
              style="font-size: 10px; margin-top: 5px; color: red"
              class="ml-2"
            >
              *Insira um telefone válido.
            </p>
          </div>

          <div class="form-group">
            <label for="password">Senha</label>
            <div class="input-group position-relative">
              <input
                id="password"
                formControlName="password"
                [type]="showPassword ? 'text' : 'password'"
                placeholder="Senha"
                class="form-control pr-5"
              />
              <i
                (click)="togglePasswordVisibility('password')"
                [ngClass]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                class="toggle-password-icon"
              ></i>
            </div>
            <p
              *ngIf="studentForm.get('password')?.value?.length < 4"
              style="font-size: 10px; margin-top: 5px; color: red"
              class="ml-2"
            >
              * Sua senha precisa ter mais de 4 caracteres.
            </p>
          </div>

          <div class="form-group">
            <label for="confirmPassword">Confirmar Senha</label>
            <div class="input-group position-relative">
              <input
                id="confirmPassword"
                formControlName="confirmPassword"
                [type]="showConfirmPassword ? 'text' : 'password'"
                placeholder="Confirmar Senha"
                class="form-control pr-5"
              />
              <i
                (click)="togglePasswordVisibility('confirmPassword')"
                [ngClass]="
                  showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'
                "
                class="toggle-password-icon"
              ></i>
            </div>
            <!-- Mensagem de senhas não correspondentes -->
            <p
              *ngIf="
                studentForm.get('password')?.value &&
                studentForm.get('confirmPassword')?.value &&
                studentForm.get('password')?.value !==
                  studentForm.get('confirmPassword')?.value
              "
              style="font-size: 10px; margin-top: 5px; color: red"
              class="ml-2"
            >
              * As senhas não correspondem.
            </p>
          </div>

          <div class="buttons">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="onCancel()"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              [disabled]="!studentForm.valid"
            >
              Cadastrar
            </button>
          </div>
        </form>
      </div>

      <ngx-spinner></ngx-spinner>
    </div>
  </div>
</div>
