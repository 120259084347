import { Component, OnInit, Input } from "@angular/core";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
@Component({
  selector: "app-invite-link-copied-modal",
  templateUrl: "./invite-link-copied-modal.component.html",
  styleUrls: ["./invite-link-copied-modal.component.scss"],
})
export class InviteLinkCopiedModalComponent implements OnInit {
  @Input() linkConviteGerado: string;
  apiInvites = this.api.new().silent(true);
  emailToInvite = null;
  emailInputVisible: boolean = false;
  email: string = "";
  alunosFiltered = [];
  linkWhatsapp: string;
  alunos = [];
  convites = [];
  apiCall = this.api.new();
  linkToSend: string;

  constructor(
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private app: App,
    private api: Api,
    private modal: NgbModal
  ) {}

  ngOnInit(): void {
    this.linkWhatsapp =
      "https://wa.me/?text=Tenho uma ótima notícia pra você! A partir de agora você receberá seus treinos através do meu app, basta clicar no link para baixar e realizar seu cadastro: " +
      encodeURIComponent(this.linkConviteGerado);
  }

  openWhatsapp(phoneCustomer) {
    let phoneFormated = phoneCustomer.replace("+", "");
    if (phoneFormated.length == 11) {
      phoneFormated = phoneFormated.padStart(13, "55");
    }
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneFormated}`,
      "_blank"
    );
  }

  toggleEmailInput() {
    this.emailInputVisible = !this.emailInputVisible;
  }

  validarEmail(email: string): boolean {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    return regex.test(email);
  }

  async sendLinkByEmail() {
    const self = this;

    try {
      if (!self.emailToInvite) {
        return;
      }

      const validaEmail = this.validarEmail(this.emailToInvite);

      if (validaEmail == false) {
        await self.app.alert(
          "Ops :(",
          "O email informado não é válido",
          "error"
        );
        return;
      }

      await self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiInvites
        .post("invite/sellercustomer", { customer: self.emailToInvite })
        .subscribe(async (data) => {
          await self.spinner.hide();
          if (data.success) {
            this.refreshRelationsAndInvites();
            await self.app.alert(
              "Tudo certo!",
              "o convite foi enviado ao email de seu aluno!",
              "success"
            );
            this.activeModal.dismiss();
            await self.cancelaConviteAluno();
          } else {
            await self.app.alert("Ops :(", data.message, "error");
          }
        });
    } catch (e) {
      await self.spinner.hide();
    }
  }

  async refreshRelationsAndInvites() {
    const self = this;

    const sellercustomer = await self.apiCall
      .get("sellercustomerplataforma")
      .pipe(take(1))
      .toPromise();
    if (sellercustomer.return) {
      let testUsers = sellercustomer.return.relations.filter(
        (x) => x.Customer.isTest == true
      );
      let realUsers = sellercustomer.return.relations.filter(
        (x) => x.Customer.isTest == null || !x.Customer.isTest
      );
      if (realUsers) {
        realUsers = realUsers.sort(
          (a, b) =>
            a.Customer.name && a.Customer.name.localeCompare(b.Customer.name)
        );
      }
      let allUsers = [];
      if (testUsers) {
        for (let t of testUsers) {
          allUsers.push(t);
        }
      }
      if (realUsers) {
        for (let t of realUsers) {
          allUsers.push(t);
        }
      }
      this.alunos = allUsers;
      this.alunosFiltered = self.alunos.filter((t) => t.deletedAt == null);
    }

    const invites = await self.apiCall
      .get("seller/invite/sellercustomer")
      .pipe(take(1))
      .toPromise();
    if (invites.return) this.convites = invites.return.rows;
  }
  nomeToInvite = null;

  async cancelaConviteAluno() {
    this.emailToInvite = null;
    this.nomeToInvite = null;
    this.linkConviteGerado = null;
  }

  close() {
    this.activeModal.dismiss();
  }
}
