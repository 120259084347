<style>
  .modal-dialog {
    position: fixed !important;
  }
</style>
<div class="row mb-2">
  <div class="d-flex justify-content-between">
    <div class="col-sm-12">
      <button
        class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton"
        (click)="OpenModalXl(contentTutoriais)"
      >
        <i class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
      </button>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-sm-12">
        <div class="d-flex justify-content-between">
          <h3 class="card-title">
            {{
              !canEdit && training
                ? nomeTreino
                : canEdit && training
                ? nomeTreino
                : "Novo Treino"
            }}
          </h3>
          <p *ngIf="training && training.type">{{ getTipoTreino() }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p>
          {{
            !canEdit && training
              ? nomeTreino
              : canEdit && training
              ? "Edite o treino e seus exercícios"
              : "Crie um treino e adicione exercícios parâmetrizados"
          }}
        </p>
      </div>
    </div>
  </div>
  <div class="card-content">
    <div class="card-body">
      <aw-wizard
        #wizard
        [defaultStepIndex]="currentIndex"
        [navBarLayout]="'large-empty-symbols'"
      >
        <aw-wizard-step
          *ngIf="!(!canEdit && training)"
          [stepTitle]="'Treino'"
          [navigationSymbol]="{ symbol: '&#xf21e;', fontFamily: 'FontAwesome' }"
        >
          <form #firstForm="ngForm" class="editForm" novalidate>
            <br />
            <div
              class="form-row"
              *ngIf="
                !training && !verificaTreinoDeAvaliacaoPreenchido() && !isModel
              "
            >
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="nome"
                    >Você pode selecionar um treino modelo</label
                  >
                  <ng-select
                    [notFoundText]="trainingModelLabelFeedback"
                    [items]="trainingsFiltered"
                    bindLabel="name"
                    name="model"
                    placeholder="Selecionar Modelo"
                    id="basic-form-model"
                    [(ngModel)]="trainingModelselecionada"
                    (ngModelChange)="onselectModel()"
                    (scrollToEnd)="onScrollEnd()"
                    (search)="onSearchChange($event)"
                    [loading]="loading"
                    [virtualScroll]="true"
                    [bufferAmount]="20"
                    [clearSearchOnAdd]="false"
                    required="true"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div id="tour-step__7" class="col-12">
                <div class="form-row">
                  <div class="col-12">
                    <div class="form-group mb-2">
                      <label for="tipotreino">{{
                        ultimoTipoSelecionado
                          ? ultimoTipoSelecionado
                          : "Tipo de Treino"
                      }}</label>
                      <ng-select
                        [notFoundText]="'Nenhum tipo encontrado'"
                        [items]="tipos"
                        [disabled]="verificaEditarTreino()"
                        bindLabel="name"
                        name="model"
                        placeholder="Selecionar Tipo"
                        id="tipotreino"
                        [(ngModel)]="tipoSelecionado"
                        (ngModelChange)="toggleTipoTreino()"
                      ></ng-select>
                    </div>
                  </div>
                </div>
                <div
                  class="form-row"
                  *ngIf="
                    tipoSelecionado &&
                    (tipoSelecionado.id == 2 || tipoSelecionado.id == 3)
                  "
                >
                  <div [ngClass]="group ? 'col-md-6 col-12' : 'col-12'">
                    <div class="form-group mb-2">
                      <label for="volt"
                        >Voltas <span style="color: red">*</span></label
                      >
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          id="volt"
                          mask="0*"
                          class="form-control"
                          name="volt"
                          [(ngModel)]="newTraining.laps"
                          #volt="ngModel"
                          gt="0"
                          required
                        />
                      </div>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="volt.errors?.required || volt.errors?.gt"
                        >Você deve informar a quantidade de voltas</small
                      >
                    </div>
                  </div>
                  <div class="col-md-6 col-12" *ngIf="group">
                    <div
                      class="form-group mb-2"
                      placement="top"
                      ngbTooltip="Válido para quando você ocultar as variáveis do último exercício."
                    >
                      <label for="volt">Intervalo entre voltas</label>
                      <div class="input-group">
                        <ngb-timepicker
                          name="breakDuration"
                          [spinners]="false"
                          [seconds]="true"
                          [(ngModel)]="newTraining.breakTime"
                          required
                          #duration1="ngModel"
                          break
                        ></ngb-timepicker>
                        <small
                          class="form-text text-muted danger"
                          *ngIf="duration1.errors?.required"
                          >Você deve informar a duração do intervalo</small
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="form-row"
                  *ngIf="tipoSelecionado && tipoSelecionado.id != 4 && !isModel"
                >
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="agend">Agendamento</label>
                      <div class="input-group mb-1">
                        <input
                          type="text"
                          autocomplete="off"
                          id="agend"
                          placeholder="00/00/0000"
                          class="form-control"
                          name="agend"
                          [(ngModel)]="agendamento"
                          ngbDatepicker
                          #agend="ngbDatepicker"
                          [container]="'body'"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-secondary input-group-text"
                            (click)="agend.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="agend">Vencimento</label>
                      <div class="input-group mb-1">
                        <input
                          type="text"
                          id="vencimento"
                          placeholder="00/00/0000"
                          class="form-control"
                          name="vencimento"
                          [(ngModel)]="vencimento"
                          ngbDatepicker
                          #venc="ngbDatepicker"
                          [container]="'body'"
                        />
                        <div class="input-group-append">
                          <button
                            type="button"
                            class="btn btn-secondary input-group-text"
                            (click)="venc.toggle()"
                          >
                            <i class="fa fa-calendar"></i>
                          </button>
                        </div>
                      </div>
                      <small
                        class="form-text text-muted danger mt-0"
                        *ngIf="errorDate"
                        >A Data de vencimento não pode ser igual a de
                        agendamento</small
                      >
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-12">
                    <div class="form-group mb-2">
                      <label for="inputAvaliacao"
                        >Nome <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        autocomplete="off"
                        id="inputAvaliacao"
                        class="form-control"
                        name="inputAvaliacao"
                        [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                        [(ngModel)]="newTraining.name"
                        #inputAvaliacao="ngModel"
                        required
                      />
                      <small
                        class="form-text text-muted danger"
                        *ngIf="inputAvaliacao.errors?.required"
                        >Informe um nome para o treino</small
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="obst"
                    >Observações
                    <span class="badge rounded-pill badge-primary"
                      >Não Utilize Emojis ex: 😀🚨</span
                    ></label
                  >
                  <textarea
                    id="obst"
                    rows="3"
                    class="form-control"
                    name="obst"
                    maxlength="500"
                    [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                    [(ngModel)]="newTraining.note"
                    #inputObs="ngModel"
                    placeholder="Você pode escrever observações sobre este treino"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <div class="d-md-none">
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-icon float-left"
                    (click)="cancelaTreino()"
                  >
                    <i class="fa fa-times"></i>
                  </button>
                  <button
                    id="tour-step__8-mobile"
                    type="button"
                    (click)="verificaTutorial()"
                    class="btn btn-outline-success btn-icon float-right"
                    [disabled]="!verificaPreenchimentoTreino()"
                    awNextStep
                  >
                    <i class="fa fa-arrow-right"></i>
                  </button>
                </div>
                <div class="d-none d-md-block">
                  <button
                    type="button"
                    class="btn btn-lg btn-danger mr-2"
                    (click)="cancelaTreino()"
                  >
                    {{ canEdit ? "Cancelar" : "Cancelar" }}
                  </button>
                  <button
                    id="tour-step__8"
                    type="button"
                    (click)="verificaTutorial()"
                    class="btn btn btn-lg btn-success"
                    [disabled]="!verificaPreenchimentoTreino()"
                    awNextStep
                  >
                    {{ canEdit ? "Próximo Passo" : "Avançar" }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step
          [stepTitle]="'Exercícios'"
          [navigationSymbol]="{ symbol: '&#xf0cb;', fontFamily: 'FontAwesome' }"
        >
          <div class="form-row mt-4">
            <div class="col-md-12 col-12 mb-3 p-0">
              <div class="float-right d-none d-md-block" role="group">
                <button
                  type="button"
                  class="btn btn-lg btn-warning mb-md-0"
                  *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                  [disabled]="listaExercicios.length < 2"
                  (click)="openAssociar(modalConjugados)"
                >
                  <fa-icon [icon]="faLayerGroup"></fa-icon> Gerenciar Conjuntos
                </button>
              </div>
              <div class="float-right d-none d-md-block mr-2" role="group">
                <button
                  id="tour-step__9"
                  type="button"
                  class="btn btn-lg btn-warning mb-md-0"
                  (click)="openExercise(modalExercicio)"
                >
                  <i class="fa fa-plus"></i> Exercício
                </button>
              </div>
              <div
                class="float-right btn-group btn-group-lg d-md-none"
                role="group"
              >
                <button
                  type="button"
                  class="btn btn-lg btn-warning mb-md-0"
                  *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                  [disabled]="listaExercicios.length < 2"
                  (click)="openAssociar(modalConjugados)"
                >
                  <fa-icon [icon]="faLayerGroup"></fa-icon>
                </button>
              </div>
              <div
                class="float-right btn-group btn-group-lg d-md-none mr-2"
                role="group"
              >
                <button
                  type="button"
                  class="btn btn-lg btn-warning mb-md-0"
                  id="tour-step__9-mobile"
                  (click)="openExercise(modalExercicio)"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <form>
            <div class="form-row" *ngIf="listaExerciciosPrincipais.length > 0">
              <ul
                class="list-group mb-2 draglist"
                cdkDropList
                style="width: 100%"
                (cdkDropListDropped)="drop($event)"
              >
                <li
                  *ngFor="let exercicio of listaExerciciosPrincipais"
                  cdkDrag
                  class="list-group-item"
                  [ngStyle]="{
                    'border-top-left-radius':
                      listaExercicios.indexOf(exercicio) == 0 ? '50px' : '0',
                    'border-top-right-radius':
                      listaExercicios.indexOf(exercicio) == 0 ? '50px' : '0',
                    'border-bottom-left-radius':
                      listaExercicios.indexOf(exercicio) + 1 ==
                      listaExercicios.length
                        ? '50px'
                        : '0',
                    'border-bottom-right-radius':
                      listaExercicios.indexOf(exercicio) + 1 ==
                      listaExercicios.length
                        ? '50px'
                        : '0'
                  }"
                >
                  <div class="media">
                    <span class="dot align-middle mr-2">{{
                      listaExerciciosPrincipais.indexOf(exercicio) + 1
                    }}</span>
                    <div class="media-body">
                      <h5 class="mt-0">
                        {{
                          getNameExercise(exercicio)
                            ? getNameExercise(exercicio)
                            : exercicio.name
                        }}
                      </h5>
                      <span *ngIf="!getNameExercise(exercicio)">{{
                        getLabelCategory(exercicio.category)
                      }}</span>
                      <span *ngIf="getNameExercise(exercicio)">
                        <div *ngFor="let conj of getResumoConjunto(exercicio)">
                          <p>
                            {{
                              (
                                getResumoConjunto(exercicio).indexOf(conj) + 1
                              ).toString() +
                                " - " +
                                conj.name
                            }}
                          </p>
                        </div>
                      </span>
                    </div>
                    <div class="d-md-none" *ngIf="getNameExercise(exercicio)">
                      <button
                        type="button"
                        class="btn btn-sm btn-outline-warning mb-2 mb-md-0"
                        placement="left"
                        ngbTooltip="Clique para visualizar os exercícios deste conjunto."
                        (click)="
                          administrarConjunto(exercicio, modalEditConjugado)
                        "
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                    </div>
                    <div
                      class="d-none d-md-block"
                      *ngIf="getNameExercise(exercicio)"
                    >
                      <button
                        type="button"
                        class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
                        placement="left"
                        ngbTooltip="Clique para visualizar os exercícios deste conjunto."
                        (click)="
                          administrarConjunto(exercicio, modalEditConjugado)
                        "
                      >
                        {{ "Visualizar " + getNameExercise(exercicio) }}
                      </button>
                    </div>
                    <div
                      class="d-none d-md-block"
                      *ngIf="!getNameExercise(exercicio)"
                    >
                      <div
                        class="btn-group btn-group-lg float-right"
                        role="group"
                      >
                        <button
                          type="button"
                          class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
                          placement="left"
                          *ngIf="tipoSelecionado && tipoSelecionado.id != 4"
                          ngbTooltip="Duplicar exercício."
                          (click)="duplicarExercicio(exercicio)"
                        >
                          <i class="fa fa-copy"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
                          placement="left"
                          role="button"
                          tabindex="0"
                          [ngbTooltip]="
                            tipoSelecionado &&
                            tipoSelecionado.id == 4 &&
                            training &&
                            training.TrainingExecution &&
                            training.TrainingExecution.length > 0
                              ? 'Visualizar exercício'
                              : 'Editar exercício.'
                          "
                          (click)="
                            editarExercicio(exercicio, modalExercicio, null)
                          "
                        >
                          <i class="fa fa-edit"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
                          [disabled]="listaExercicios.length <= 1"
                          placement="left"
                          ngbTooltip="Remover exercício."
                          (click)="removerExercicio(exercicio)"
                          *ngIf="verificaExercicio(exercicio)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                    <div
                      class="d-md-none"
                      role="group"
                      *ngIf="!getNameExercise(exercicio)"
                    >
                      <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                        <button
                          type="button"
                          class="btn btn-outline-warning btn-sm dropdown-toggle"
                          ngbDropdownToggle
                        >
                          <i class="ft-more-vertical"></i>
                        </button>
                        <div ngbDropdownMenu class="dropdown-menu" role="menu">
                          <a
                            class="dropdown-item"
                            (click)="duplicarExercicio(exercicio)"
                            *ngIf="tipoSelecionado && tipoSelecionado.id != 4"
                            >Duplicar exercício</a
                          >
                          <a
                            class="dropdown-item"
                            (click)="
                              editarExercicio(exercicio, modalExercicio, null)
                            "
                            >{{
                              tipoSelecionado &&
                              tipoSelecionado.id == 4 &&
                              training &&
                              training.TrainingExecution &&
                              training.TrainingExecution.length > 0
                                ? "Visualizar exercício"
                                : "Editar exercício."
                            }}</a
                          >
                          <a
                            class="dropdown-item"
                            (click)="removerExercicio(exercicio)"
                            *ngIf="verificaExercicio(exercicio)"
                            >Remover exercício</a
                          >
                        </div>
                      </div>
                    </div>
                    <div class="mb-2 mt-2 ml-3">
                      <i
                        class="fa fa-reorder mr-2 text-muted"
                        cdkDragHandle
                        style="font-size: 25px; cursor: pointer"
                        placement="left"
                        ngbTooltip="Arraste para reordenar este exercício."
                      ></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="form-row" *ngIf="listaExercicios.length == 0">
              <ul class="list-group mb-2" style="width: 100%; overflow-y: auto">
                <li class="list-group-item">
                  <div class="media">
                    <div class="media-body">
                      <h5 class="mt-0">Nenhum exercício cadastrado.</h5>
                      <span>Adicione um novo exercício!</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="form-row mt-4" *ngIf="!idTreino">
              <div class="col-12 text-center">
                <p>
                  Você precisa <b>publicar</b> este treino para que ele seja
                  salvo
                </p>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-lg btn-secondary mr-2"
                  awPreviousStep
                >
                  Voltar
                </button>

                <button
                  type="button"
                  id="tour-step__10"
                  class="btn btn-lg btn-success"
                  [disabled]="
                    listaExercicios.length == 0 ||
                    !verificaPreenchimentoTreino() ||
                    verificaTreinoDeAvaliacaoPreenchido()
                  "
                  (click)="cadastrarTreino(true)"
                >
                  <i class="fa fa-save mr-2"></i
                  >{{ idTreino ? "Salvar Treino" : "Publicar" }}
                </button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
</div>

<ng-template #modalExercicio let-modal style="position: fixed !important">
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">
        {{
          isEditingExercise
            ? "Editar exercício do treino"
            : "Adicionar exercício ao treino"
        }}
        {{ nomeTreino }}
      </h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
          <aw-wizard-step
            [stepTitle]="'Exercício'"
            [navigationSymbol]="{
              symbol: '&#xf0cb;',
              fontFamily: 'FontAwesome'
            }"
          >
            <form #firstForm="ngForm" class="editForm" novalidate>
              <br />
              <div class="form-row">
                <div class="col-md-12 col-12">
                  <div class="float-right d-none d-md-block" role="group">
                    <button
                      type="button"
                      class="btn btn-lg btn-warning mb-md-0"
                      (click)="addExercise()"
                    >
                      <i class="fa fa-plus"></i> Criar Exercício
                    </button>
                  </div>
                  <div
                    class="btn-group btn-group-lg d-md-none float-right"
                    role="group"
                  >
                    <button
                      type="button"
                      class="btn btn-lg btn-warning mb-md-0"
                      (click)="addExercise()"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-row mt-3">
                <div
                  class="col-12 d-flex justify-content-center align-items-center mb-1 px-2 px-lg-3 py-2 bg-grey bg-lighten-2 rounded"
                >
                  <svg
                    fill="currentColor"
                    height="18px"
                    width="18px"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 512 512"
                    xml:space="preserve"
                    class="text-primary flex-shrink-0"
                  >
                    <g>
                      <g>
                        <path
                          d="M344.322,404.056c-6.585-6.585-227.74-227.745-236.388-236.394c-6.529-6.529-17.108-6.529-23.637,0
			c-6.529,6.523-6.529,17.112,0,23.637l16.186,16.187L0.954,489.705c-2.144,6.072-0.61,12.831,3.945,17.38
			c4.58,4.58,11.353,6.074,17.38,3.945l282.214-99.53l16.193,16.193c6.529,6.529,17.107,6.53,23.637,0
			C350.851,421.169,350.851,410.58,344.322,404.056z M138.479,434.6l-61.095-61.089l18.492-52.43l95.033,95.028L138.479,434.6z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M384.675,127.309c-6.529-6.529-17.108-6.529-23.637,0L256.686,231.656c-6.529,6.523-6.529,17.108,0,23.637
			c6.529,6.529,17.107,6.53,23.637,0l104.352-104.347C391.204,144.423,391.204,133.838,384.675,127.309z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M367.285,280.959h-58.417c-9.228,0-16.715,7.482-16.715,16.715c0,9.234,7.487,16.715,16.715,16.715h58.417
			c9.228,0,16.715-7.482,16.715-16.715C384,288.44,376.513,280.959,367.285,280.959z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M214.31,127.985c-9.228,0-16.715,7.481-16.715,16.715v58.423c0,9.234,7.487,16.715,16.715,16.715
			s16.715-7.482,16.715-16.715V144.7C231.025,135.467,223.538,127.985,214.31,127.985z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M497.296,262.22c-19.578-19.589-51.312-19.603-70.91-0.006c-19.592,19.579-19.621,51.323,0,70.921
			c19.579,19.59,51.317,19.604,70.91,0C516.887,313.556,516.917,281.817,497.296,262.22z M473.661,309.492c0,0.006,0,0.006,0,0.006
			c-9.982,9.982-28.533,3.47-28.533-11.823c0-9.164,7.406-16.71,16.715-16.71C476.781,280.964,484.096,299.057,473.661,309.492z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M249.765,14.675C230.221-4.869,198.4-4.869,178.834,14.686c-19.534,19.55-19.534,51.36,0.011,70.91
			c19.541,19.554,51.377,19.549,70.92,0.006v-0.006C269.36,66.025,269.38,34.29,249.765,14.675z M226.129,61.955v0.006
			c-6.519,6.513-17.108,6.523-23.648,0c-6.508-6.519-6.508-17.124,0-23.637c6.521-6.511,17.099-6.532,23.648-0.006
			C232.676,44.877,232.652,55.432,226.129,61.955z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <circle cx="427.057" cy="203.115" r="16.715" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <circle cx="308.868" cy="84.926" r="16.715" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <circle cx="427.057" cy="392.233" r="16.715" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <circle cx="119.75" cy="84.926" r="16.715" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M443.777,1.345c-9.228,0-16.715,7.482-16.715,16.715v16.715c0,9.234,7.487,16.715,16.715,16.715
			s16.715-7.482,16.715-16.715V18.061C460.492,8.827,453.005,1.345,443.777,1.345z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M443.777,84.922c-9.228,0-16.715,7.482-16.715,16.715v16.715c0,9.234,7.487,16.715,16.715,16.715
			s16.715-7.482,16.715-16.715v-16.715C460.492,92.404,453.005,84.922,443.777,84.922z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M410.346,51.492h-16.715c-9.228,0-16.715,7.482-16.715,16.715s7.487,16.715,16.715,16.715h16.715
			c9.228,0,16.715-7.482,16.715-16.715S419.574,51.492,410.346,51.492z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M493.923,51.492h-16.715c-9.228,0-16.715,7.482-16.715,16.715s7.487,16.715,16.715,16.715h16.715
			c9.228,0,16.715-7.482,16.715-16.715S503.151,51.492,493.923,51.492z"
                        />
                      </g>
                    </g>
                  </svg>
                  <span class="ml-2">
                    Agora você pode adicionar exercícios sem precisar selecionar
                    a categoria.
                  </span>
                </div>
                <div class="form-group col-md-6 col-12 mb-2">
                  <label for="catselect">Selecione uma categoria</label>
                  <ng-select
                    [items]="categories"
                    [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                    [dropdownPosition]="'top'"
                    [notFoundText]="'Nenhuma categoria encontrada'"
                    bindLabel="name"
                    name="catselect"
                    placeholder="Selecionar Categoria"
                    id="catselect"
                    [(ngModel)]="categoriaSelecionada"
                    (ngModelChange)="onSelectCategory()"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{ item.name }}</span>
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                    >
                      <span>
                        {{ item.name }}
                        <small class="text-muted float-right">
                          {{ item.user ? "personal" : "plataforma" }}
                        </small>
                      </span>
                    </ng-template>
                  </ng-select>
                </div>
                <div class="form-group col-md-6 col-12 mb-2">
                  <label for="exercSelect">Selecione um exercício</label>
                  <ng-select
                    id="exercSelect"
                    [items]="exercisesList"
                    [loading]="exercisesLoading"
                    [typeahead]="$exercSelectInput"
                    [notFoundText]="'Nenhum exercício encontrado'"
                    [loadingText]="'Carregando...'"
                    [dropdownPosition]="'top'"
                    [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                    name="exercSelect"
                    bindLabel="Exercise.name"
                    placeholder="Selecionar Exercício"
                    [(ngModel)]="exercicioSelecionado"
                    (ngModelChange)="onSelectExercise()"
                    (scrollToEnd)="fetchMoreExercises(exercSelect.searchTerm)"
                    #exercSelect
                    required
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{ item.Exercise.name }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      <span>
                        {{ item.Exercise.name }}
                        <small class="text-muted float-right">
                          {{ item.Exercise.user ? "personal" : "plataforma" }}
                        </small>
                      </span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="form-group mb-2" *ngIf="exercicioSelecionado">
                <label for="mediaTypeRadio">Mídia</label>
                <div id="mediaTypeRadio" class="col">
                  <ul class="list-unstyled mb-0">
                    <div class="mb-2 d-md-inline-block mr-lg-4">
                      <li
                        class="d-inline-block mr-2"
                        style="vertical-align: middle"
                        *ngIf="
                          exercicioSelecionado.Exercise &&
                          exercicioSelecionado.Exercise.url_video
                        "
                      >
                        <div class="radio radio-sm">
                          <input
                            type="radio"
                            name="size-radio-1"
                            [value]="true"
                            id="size-sm"
                            (click)="switchMidia(true)"
                            [(ngModel)]="isVideo"
                          />
                          <label for="size-sm"
                            ><a class="text-primary">Escolher Vídeo</a></label
                          >
                        </div>
                      </li>
                      <button
                        class="btn btn-sm btn-warning"
                        (click)="viewMidia(true, contentVideo, contentGif)"
                      >
                        <i class="fa fa-eye"></i> Ver Vídeo
                      </button>
                    </div>
                    <div class="mb-2 d-md-inline-block">
                      <li
                        class="d-inline-block mr-2"
                        style="vertical-align: middle"
                        *ngIf="
                          exercicioSelecionado.Exercise &&
                          exercicioSelecionado.Exercise.url_gif
                        "
                      >
                        <div class="radio radio-sm">
                          <input
                            type="radio"
                            name="size-radio-1"
                            [value]="false"
                            id="size-default"
                            (click)="switchMidia(false)"
                            [(ngModel)]="isVideo"
                          />
                          <label for="size-default"
                            ><a class="text-primary">Escolher GIF</a></label
                          >
                        </div>
                      </li>
                      <button
                        class="btn btn-sm btn-warning"
                        (click)="viewMidia(false, contentVideo, contentGif)"
                      >
                        <i class="fa fa-eye"></i> Ver GIF
                      </button>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-12 text-center">
                  <div class="d-md-none">
                    <button
                      type="button"
                      class="btn btn-outline-danger btn-icon float-left"
                      (click)="modal.close('Close click')"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-outline-success btn-icon float-right"
                      id="step12m"
                      [disabled]="!verificaPrePreenchimentoExercicio()"
                      awNextStep
                    >
                      <i class="fa fa-arrow-right"></i>
                    </button>
                  </div>
                  <div class="d-none d-md-block">
                    <button
                      type="button"
                      class="btn btn-lg btn-danger mr-2"
                      [ngClass]="canEdit ? 'btn-danger' : 'btn-secondary'"
                      (click)="modal.close('Close click')"
                    >
                      {{ canEdit ? "Cancelar" : "Cancelar" }}
                    </button>
                    <button
                      type="button"
                      class="btn btn btn-lg btn-success"
                      id="step12"
                      [disabled]="!verificaPrePreenchimentoExercicio()"
                      awNextStep
                    >
                      {{ canEdit ? "Próximo Passo" : "Avançar" }}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </aw-wizard-step>
          <aw-wizard-step
            [stepTitle]="'Parâmetros'"
            [navigationSymbol]="{
              symbol: '&#xf085;',
              fontFamily: 'FontAwesome'
            }"
          >
            <form
              #workForm="ngForm"
              class="editForm mb-2"
              style="
                padding-left: 7px;
                padding-right: 7px;
                padding-top: 5px;
                padding-bottom: 5px;
                border-radius: 50px;
              "
              novalidate
            >
              <ng-template [ngIf]="group">
                <div class="form-row mt-3">
                  <div class="col-md-6 col-12">
                    <div class="form-group mr-2 float-right">
                      <label for="ttt" class="mr-2">Ocultar Variáveis</label>
                      <ui-switch
                        class="switchery switchery-warning"
                        id="ttt"
                        [checked]="switchHideVariables"
                        (change)="changeSwitchVariables($event)"
                      ></ui-switch>
                    </div>
                  </div>
                </div>
              </ng-template>
              <br />
              <div
                class="form-row"
                *ngIf="
                  exercicioSelecionado &&
                  tipoSelecionado &&
                  (tipoSelecionado.id == 2 || tipoSelecionado.id == 3)
                "
              >
                <div
                  class="col-md-3 col-12"
                  placement="top"
                  ngbTooltip="Você pode marcar este exercício como aquecimento. Assim ele será executado antes dos exercícios principais."
                >
                  <div class="form-group mb-2">
                    <label for="aqc">Aquecimento</label>
                    <div class="input-group">
                      <ui-switch
                        id="aqc"
                        class="switchery switchery-warning"
                        [checked]="switchAquecimentoMode"
                        (change)="switchAquecimento($event)"
                      ></ui-switch>
                    </div>
                  </div>
                </div>
              </div>

              <!--CAMPOS REORGANIZADOS SILVANO-->
              <ng-template
                [ngIf]="
                  tipoSelecionado &&
                  tipoSelecionado.id != 4 &&
                  !switchHideVariables
                "
              >
                <div class="form-row">
                  <div
                    class="col-md-3 col-12"
                    *ngIf="
                      (tipoSelecionado && tipoSelecionado.id == 1) ||
                      switchAquecimentoMode
                    "
                  >
                    <div class="form-group mb-2">
                      <label for="series"
                        >Séries
                        <span
                          *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                          style="color: red"
                          >*</span
                        ></label
                      >
                      <div class="input-group">
                        <input
                          type="text"
                          id="series"
                          mask="0*"
                          class="form-control"
                          name="series"
                          [(ngModel)]="exercicie.series"
                          #series="ngModel"
                          gt="0"
                          required
                        />
                      </div>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="
                          (series.errors?.required || series.errors?.gt) &&
                          tipoSelecionado &&
                          tipoSelecionado.id == 1
                        "
                        >Você deve informar a quantidade de séries</small
                      >
                    </div>
                  </div>

                  <div
                    [ngClass]="
                      (tipoSelecionado && tipoSelecionado.id == 1) ||
                      switchAquecimentoMode
                        ? 'col-md-2 col-12'
                        : 'col-md-3 col-12'
                    "
                  >
                    <div class="form-group mb-2">
                      <label for="rep">Tipo</label>
                      <div class="input-group">
                        <div
                          class="d-flex d-md-none justify-content-center align-items-center"
                          role="group"
                        >
                          <img
                            src="./assets/img/repeticoes.svg"
                            alt="Logo"
                            class="mr-1 duration-switch-imgs"
                          /><ui-switch
                            class="switchery switchery-warning"
                            size="small"
                            id="bla"
                            [checked]="switchRepetitionAndDuration"
                            (change)="switchRepAndDur($event)"
                          ></ui-switch
                          ><img
                            src="./assets/img/stopwatch.svg"
                            alt="Logo"
                            class="ml-1 duration-switch-imgs"
                          />
                        </div>
                        <div
                          class="d-none d-md-flex justify-content-center align-items-center"
                          role="group"
                        >
                          <img
                            src="./assets/img/repeticoes.svg"
                            alt="Logo"
                            class="mr-1 duration-switch-imgs"
                          /><ui-switch
                            class="switchery switchery-warning"
                            id="bla"
                            [checked]="switchRepetitionAndDuration"
                            (change)="switchRepAndDur($event)"
                          ></ui-switch
                          ><img
                            src="./assets/img/stopwatch.svg"
                            alt="Logo"
                            class="ml-1 duration-switch-imgs"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    [ngClass]="
                      (tipoSelecionado && tipoSelecionado.id == 1) ||
                      switchAquecimentoMode
                        ? 'col-md-3 col-12'
                        : 'col-md-4 col-12'
                    "
                  >
                    <div class="form-group mb-2">
                      <label for="rep"
                        >{{
                          switchRepetitionAndDuration
                            ? "Duração"
                            : "Repetições"
                        }}<span style="color: red">*</span></label
                      >
                      <ng-template [ngIf]="!switchRepetitionAndDuration">
                        <div class="input-group">
                          <input
                            type="text"
                            autocomplete="off"
                            id="rep"
                            class="form-control"
                            name="rep"
                            [(ngModel)]="exercicie.repetitions"
                            maxlength="11"
                            #repetitions="ngModel"
                            required
                            aria-describedby="group-repetition"
                          />
                          <div class="input-group-append">
                            <span class="input-group-text" id="group-repetition"
                              >x</span
                            >
                          </div>
                        </div>
                        <small
                          class="form-text text-muted danger"
                          *ngIf="
                            repetitions.errors?.required ||
                            repetitions.errors?.gt
                          "
                          >Você deve informar a quantidade de repetições</small
                        >
                      </ng-template>
                      <ng-template [ngIf]="switchRepetitionAndDuration">
                        <ngb-timepicker
                          name="timerDuration"
                          [spinners]="false"
                          [seconds]="true"
                          [(ngModel)]="exercicie.timeDuration"
                          required
                          #duration1="ngModel"
                        ></ngb-timepicker>
                        <small
                          class="form-text text-muted danger"
                          *ngIf="duration1.errors?.required"
                          >Você deve informar a duração</small
                        >
                      </ng-template>
                    </div>
                  </div>
                  <div
                    [ngClass]="
                      (tipoSelecionado && tipoSelecionado.id == 1) ||
                      switchAquecimentoMode
                        ? 'col-md-4 col-12'
                        : 'col-md-5 col-12'
                    "
                  >
                    <div class="form-group mb-2">
                      <div class="d-md-none" role="group">
                        <label for="carga">Carga Total</label>
                      </div>
                      <div class="d-none d-md-block">
                        <label for="carga">Carga sugerida total</label>
                      </div>
                      <div class="input-group">
                        <div
                          class="input-group-prepend"
                          placement="top"
                          ngbTooltip="Clique para informar peso corporal."
                        >
                          <div class="input-group-text" id="checkbox-addon1">
                            <div class="checkbox checkbox-sm">
                              <input
                                type="checkbox"
                                id="checkboxInputGroup"
                                [(ngModel)]="exercicie.corporalLoad"
                                name="checkbox"
                              />
                              <label for="checkboxInputGroup"></label>
                            </div>
                          </div>
                        </div>
                        <input
                          type="number"
                          autocomplete="off"
                          id="carga"
                          class="form-control"
                          [disabled]="exercicie.corporalLoad"
                          name="carga"
                          [(ngModel)]="exercicie.load"
                          #carga="ngModel"
                          gt="0"
                          aria-describedby="checkbox-addon1"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-carga"
                            >KG</span
                          >
                        </div>
                      </div>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="carga.errors?.required || carga.errors?.gt"
                        >Você deve informar a carga total</small
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-12" *ngIf="exercicie.loadFeedback">
                    <div class="form-group mb-2">
                      <label for="loadFeedback">Feedback Carga Total</label>
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          id="loadFeedback"
                          class="form-control"
                          name="loadFeedback"
                          [disabled]="true"
                          [value]="
                            exercicie.loadFeedback != null
                              ? exercicie.loadFeedback
                              : 'N/A'
                          "
                          aria-describedby="group-loadFeedback"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-loadFeedback"
                            >KG</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

              <!-- <ng-template [ngIf]="(tipoSelecionado && tipoSelecionado.id==1 || switchAquecimentoMode) && !switchHideVariables">
                <div class="form-row">
                  <div class="col-md-3 col-12" [ngClass]="(tipoSelecionado && tipoSelecionado.id!=1 && switchAquecimentoMode) ? 'col-md-3 col-12' : 'col-md-3 col-12'">
                    <div class="form-group mb-2">
                      <label for="series">Séries <span *ngIf="tipoSelecionado && tipoSelecionado.id==1" style="color: red">*</span></label>
                      <div class="input-group">
                        <input type="text" id="series" mask="0*" class="form-control" name="series" [(ngModel)]="exercicie.series" #series="ngModel" gt="0" required>
                      </div>
                      <small class="form-text text-muted danger" *ngIf="(series.errors?.required || series.errors?.gt) && tipoSelecionado && tipoSelecionado.id==1">Você deve informar a quantidade de séries</small>
                    </div>
                  </div>
                  </div>
                <div class="form-row" *ngIf="tipoSelecionado && tipoSelecionado.id==1">
                  <div class="col-md-2 col-12">
                    <div class="form-group mb-2">
                      <label for="rep">Tipo</label>
                      <div class="input-group" >
                        <div class="d-md-none" role="group">
                          <ui-switch class="switchery switchery-warning" size="small" id="bla" [checked]="switchRepetitionAndDuration" (change)="switchRepAndDur($event)"></ui-switch>
                        </div>
                        <div class="d-none d-md-block">
                          <ui-switch class="switchery switchery-warning"  id="bla"  [checked]="switchRepetitionAndDuration" (change)="switchRepAndDur($event)"></ui-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 col-12">
                    <div class="form-group mb-2">
                      <label for="rep">{{switchRepetitionAndDuration ? 'Duração' : 'Repetições'}}<span style="color: red">*</span></label>
                      <ng-template [ngIf]="!switchRepetitionAndDuration">
                        <div class="input-group" >
                          <input type="text" autocomplete="off" id="rep" class="form-control" name="rep" [(ngModel)]="exercicie.repetitions" maxlength="11" #repetitions="ngModel" required aria-describedby="group-repetition" >
                          <div class="input-group-append">
                            <span class="input-group-text" id="group-repetition">x</span>
                          </div>
                        </div>
                        <small class="form-text text-muted danger" *ngIf="repetitions.errors?.required || repetitions.errors?.gt">Você deve informar a quantidade de repetições</small>
                      </ng-template>
                      <ng-template [ngIf]="switchRepetitionAndDuration">
                        <ngb-timepicker name="timerDuration" [spinners]="false" [seconds]="true" [(ngModel)]="exercicie.timeDuration" required #duration1="ngModel"></ngb-timepicker>
                        <small class="form-text text-muted danger" *ngIf="duration1.errors?.required">Você deve informar a duração</small>
                      </ng-template>
                    </div>
                  </div>
                  <div [ngClass]="exercicie.loadFeedback ? 'col-md-3 col-12' : 'col-md-5 col-12'">
                    <div class="form-group mb-2">
                      <div class="d-md-none" role="group">
                        <label for="carga">Carga Total</label>
                      </div>
                      <div class="d-none d-md-block">
                        <label for="carga">Carga sugerida total</label>
                      </div>
                      <div class="input-group">
                        <div class="input-group-prepend" placement="top" ngbTooltip="Clique para informar peso corporal.">
                          <div class="input-group-text" id="checkbox-addon1">
                            <div class="checkbox checkbox-sm">
                              <input type="checkbox" id="checkboxInputGroup" [(ngModel)]="exercicie.corporalLoad" name="checkbox">
                              <label for="checkboxInputGroup"></label>
                            </div>
                          </div>
                        </div>
                        <input type="text" autocomplete="off" id="carga"  class="form-control" [disabled]="exercicie.corporalLoad" name="carga" [(ngModel)]="exercicie.load" #carga="ngModel" gt="0" aria-describedby="checkbox-addon1">
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-carga">KG</span>
                        </div>
                      </div>
                      <small class="form-text text-muted danger" *ngIf="carga.errors?.required || carga.errors?.gt">Você deve informar a carga total</small>
                    </div>
                  </div>
                  <div class="col-md-2 col-12" *ngIf="exercicie.loadFeedback">
                    <div class="form-group mb-2">
                      <label for="loadFeedback">Feedback Carga Total</label>
                      <div class="input-group">
                        <input type="text" autocomplete="off" id="loadFeedback"  class="form-control" name="loadFeedback" [disabled]="true" [value]="exercicie.loadFeedback!=null ? exercicie.loadFeedback : 'N/A'"   aria-describedby="group-loadFeedback" >
                        <div class="input-group-append" >
                          <span class="input-group-text" id="group-loadFeedback">KG</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template> -->

              <!-- <ng-template [ngIf]="tipoSelecionado && (tipoSelecionado.id==2 || tipoSelecionado.id==3) && !switchHideVariables">
                <div class="form-row">
                  <div class="col-md-2 col-12">
                    <div class="form-group mb-2">
                      <label for="rep">Tipo</label>
                      <div class="input-group" >
                        <div class="d-md-none" role="group">
                          <ui-switch class="switchery switchery-warning" size="small" id="bla" [checked]="switchRepetitionAndDuration" (change)="switchRepAndDur($event)"></ui-switch>
                        </div>
                        <div class="d-none d-md-block">
                          <ui-switch class="switchery switchery-warning"  id="bla"  [checked]="switchRepetitionAndDuration" (change)="switchRepAndDur($event)"></ui-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5 col-12">
                    <div class="form-group mb-2">
                      <label for="rep">{{switchRepetitionAndDuration ? 'Duração' : 'Repetições'}}<span style="color: red">*</span></label>
                      <ng-template [ngIf]="!switchRepetitionAndDuration">
                        <div class="input-group" >
                          <input type="text" autocomplete="off" id="rep" class="form-control" name="rep" [(ngModel)]="exercicie.repetitions" #repetitions="ngModel" required aria-describedby="group-repetition" maxlength="11" >
                          <div class="input-group-append">
                            <span class="input-group-text" id="group-repetition">x</span>
                          </div>
                        </div>
                        <small class="form-text text-muted danger" *ngIf="repetitions.errors?.required || repetitions.errors?.gt">Você deve informar a quantidade de repetições</small>
                      </ng-template>
                      <ng-template [ngIf]="switchRepetitionAndDuration">
                        <ngb-timepicker name="timerDuration" [spinners]="false" [seconds]="true" [(ngModel)]="exercicie.timeDuration" required #duration1="ngModel"></ngb-timepicker>
                        <small class="form-text text-muted danger" *ngIf="duration1.errors?.required">Você deve informar a duração 2</small>
                      </ng-template>
                    </div>
                  </div>


                  <div [ngClass]="exercicie.loadFeedback ? 'col-md-3 col-12' : 'col-md-5 col-12'">
                    <div class="form-group mb-2">
                      <div class="d-md-none" role="group">
                        <label for="carga">Carga Total</label>
                      </div>
                      <div class="d-none d-md-block">
                        <label for="carga">Carga sugerida total</label>
                      </div>
                      <div class="input-group">
                        <div class="input-group-prepend" placement="top" ngbTooltip="Clique para informar peso corporal.">
                          <div class="input-group-text" id="checkbox-addon1">
                            <div class="checkbox checkbox-sm">
                              <input type="checkbox" id="checkboxInputGroup" [(ngModel)]="exercicie.corporalLoad" name="checkbox">
                              <label for="checkboxInputGroup"></label>
                            </div>
                          </div>
                        </div>
                        <input type="text" autocomplete="off" id="carga"  class="form-control" [disabled]="exercicie.corporalLoad" name="carga" [(ngModel)]="exercicie.load" #carga="ngModel" gt="0" aria-describedby="checkbox-addon1">
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-carga">KG</span>
                        </div>
                      </div>
                      <small class="form-text text-muted danger" *ngIf="carga.errors?.required || carga.errors?.gt">Você deve informar a carga total</small>
                    </div>
                  </div>
                  <div class="col-md-2 col-12" *ngIf="exercicie.loadFeedback">
                    <div class="form-group mb-2">
                      <label for="loadFeedback">Feedback Carga Total</label>
                      <div class="input-group">
                        <input type="text" autocomplete="off" id="loadFeedback"  class="form-control" name="loadFeedback" [disabled]="true" [value]="exercicie.loadFeedback!=null ? exercicie.loadFeedback : 'N/A'"   aria-describedby="group-loadFeedback" >
                        <div class="input-group-append" >
                          <span class="input-group-text" id="group-loadFeedback">KG</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template> -->
              <ng-template
                [ngIf]="
                  tipoSelecionado &&
                  tipoSelecionado.id !== 4 &&
                  !switchHideVariables
                "
              >
                <div class="form-row">
                  <div class="col-md-6 col-12">
                    <div class="form-group mb-2">
                      <label for="carga">Velocidade</label>
                      <ng-select
                        id="carga"
                        [items]="velocidade"
                        name="velocidade"
                        [notFoundText]="'Nenhuma velocidade encontrada'"
                        placeholder="Selecionar Velocidade"
                        [(ngModel)]="exercicie.speed"
                      ></ng-select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group mb-2">
                      <label for="interval"
                        >Descanso<span
                          *ngIf="
                            tipoSelecionado.id != 2 &&
                            exercicie.conjugado == null
                          "
                          style="color: red"
                          >*</span
                        ></label
                      >
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          id="interval"
                          mask="9*"
                          class="form-control"
                          name="interval"
                          [(ngModel)]="exercicie.breakTime"
                          #interval="ngModel"
                          gt="0"
                          aria-describedby="group-interval"
                          [required]="
                            tipoSelecionado.id == 3 ||
                            (tipoSelecionado.id == 1 &&
                              exercicie.conjugado == null)
                          "
                        />

                        <div class="input-group-append">
                          <span class="input-group-text" id="group-interval"
                            >segundos</span
                          >
                        </div>
                      </div>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="
                          (interval.errors?.required || interval.errors?.gt) &&
                          exercicie.conjugado == null
                        "
                        >Você deve informar o intervalo</small
                      >
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template
                [ngIf]="
                  tipoSelecionado &&
                  tipoSelecionado.id == 4 &&
                  !exercicie.loadFeedback &&
                  !exercicie.repetitionFeedback &&
                  !exercicie.timeFeedback &&
                  !switchHideVariables
                "
              >
                <div class="form-row mb-2 mt-2">
                  <div class="col-md-4 col-12">
                    <div
                      class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0"
                      placement="top"
                      ngbTooltip="Informe se deseja solicitar o total de repetições."
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="color-switch-1"
                        [(ngModel)]="exercicie.showRepetitionFeedback"
                        name="showRepetitionFeedback"
                      />
                      <label
                        class="custom-control-label mr-1"
                        for="color-switch-1"
                      >
                        <span>Total de Repetições</span>
                      </label>
                    </div>
                  </div>
                  <div
                    class="col-md-4 col-12"
                    placement="top"
                    ngbTooltip="Informe se deseja solicitar o tempo total de execução."
                  >
                    <div
                      class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="color-switch-2"
                        [(ngModel)]="exercicie.showTimeFeedback"
                        name="showTimeFeedback"
                      />
                      <label
                        class="custom-control-label mr-1"
                        for="color-switch-2"
                      >
                        <span>Tempo Total de Execução</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div
                      class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0"
                      placement="top"
                      ngbTooltip="Informe se deseja solicitar a carga total utilizada."
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="color-switch-3"
                        [(ngModel)]="exercicie.showLoadFeedback"
                        name="showLoadFeedback"
                      />
                      <label
                        class="custom-control-label mr-1"
                        for="color-switch-3"
                      >
                        <span>Total de Carga</span>
                      </label>
                    </div>
                  </div>
                </div>
              </ng-template>
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="obse"
                      >Observações
                      <span class="badge rounded-pill badge-primary"
                        >Não Utilize Emojis ex: 😀🚨</span
                      ></label
                    >
                    <textarea
                      id="obse"
                      rows="2"
                      class="form-control"
                      name="obse"
                      maxlength="500"
                      [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                      [(ngModel)]="exercicie.note"
                      #inputObse="ngModel"
                      placeholder="Você pode escrever observações sobre este exercício"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div
                class="row"
                *ngIf="
                  tipoSelecionado &&
                  tipoSelecionado.id !== 4 &&
                  !switchHideVariables
                "
              >
                <div class="col-md-12 col-12">
                  <div class="form-group mb-2">
                    <label
                      ngbTooltip="Controle apenas visual para ocultar ou exibir a sessão das periodizações do exercício. Caso haja alguma periodização cadastrada no exercício você não conseguirá desativar este controle."
                      >Exibir Periodizações?
                      <i class="fa fa-question-circle"></i
                    ></label>
                    <ui-switch
                      class="switchery switchery-warning ml-2"
                      id="periodizacao"
                      [disabled]="this.progressions.length > 0"
                      [checked]="switchPeriodizacaoMode"
                      (change)="switchPeriodizacao($event)"
                    ></ui-switch>
                  </div>
                </div>
              </div>

              <ng-template [ngIf]="switchPeriodizacaoMode">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <p
                      role="button"
                      tabindex="0"
                      placement="top"
                      ngbTooltip="Aqui você pode configurar periodizações automáticas de qualquer variável deste exercício e a partir de qualquer execução deste treino. Caso não queira alterar alguma variável na periodização basta não preencher a mesma."
                    >
                      Periodizações Automáticas
                      <i class="fa fa-question-circle"></i>
                    </p>
                  </div>
                  <div
                    class="col-md-6 col-12"
                    *ngIf="!isAddingProgression && !isEditingProgression"
                  >
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-secondary btn-icon float-right"
                      (click)="liberaCadastroProgressao()"
                    >
                      <i class="fa fa-plus-circle"></i
                      ><span class="d-none d-md-inline-block ml-2"
                        >adicionar periodização</span
                      >
                    </button>
                  </div>
                </div>
                <div
                  class="row mb-2"
                  *ngIf="isAddingProgression || isEditingProgression"
                >
                  <div class="col-sm">
                    <div class="form-group mb-2">
                      <label>Tipo de Periodização:</label>
                      <ui-switch
                        class="switchery switchery-warning ml-2"
                        [checked]="switchExecutionAndDate"
                        (change)="switchExeAndDate($event)"
                      >
                      </ui-switch>
                    </div>
                  </div>
                </div>
                <div
                  class="row mb-2"
                  *ngIf="isAddingProgression || isEditingProgression"
                >
                  <div class="col-sm" *ngIf="switchExecutionAndDate">
                    <div class="form-group">
                      <label for="pe"
                        >Execução
                        <i
                          class="fa fa-question-circle"
                          style="cursor: pointer"
                          role="button"
                          tabindex="0"
                          placement="top"
                          [ngbTooltip]="tipExecProg"
                        ></i
                      ></label>
                      <input
                        type="text"
                        autocomplete="off"
                        id="pe"
                        class="form-control"
                        mask="9*"
                        name="pe"
                        [(ngModel)]="newProgression.execution"
                        #ipe="ngModel"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-sm" *ngIf="!switchExecutionAndDate">
                    <div class="form-group">
                      <label for="progressionDate"
                        >Data
                        <i
                          class="fa fa-question-circle"
                          style="cursor: pointer"
                          role="button"
                          tabindex="0"
                          placement="top"
                          [ngbTooltip]="tipDataProg"
                        ></i
                      ></label>
                      <input
                        type="date"
                        autocomplete="off"
                        id="progressionDate"
                        class="form-control"
                        name="progressionDate"
                        [(ngModel)]="newProgression.progressionDate"
                        #iprogressionDate="ngModel"
                        required
                      />
                    </div>
                  </div>
                  <div
                    class="col-sm"
                    *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                  >
                    <div class="form-group">
                      <label for="ps"
                        >Séries
                        <i
                          class="fa fa-question-circle"
                          style="cursor: pointer"
                          role="button"
                          tabindex="0"
                          placement="top"
                          [ngbTooltip]="tipSerProg"
                        ></i
                      ></label>
                      <input
                        type="text"
                        autocomplete="off"
                        id="ps"
                        class="form-control"
                        mask="9*"
                        name="ps"
                        [(ngModel)]="newProgression.series"
                        #ips="ngModel"
                      />
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <label for="pr"
                        >{{
                          switchRepetitionAndDuration ? "duração" : "repetições"
                        }}
                        <i
                          class="fa fa-question-circle"
                          style="cursor: pointer"
                          role="button"
                          tabindex="0"
                          placement="top"
                          [ngbTooltip]="tipDurProg"
                        ></i
                      ></label>
                      <ng-template [ngIf]="switchRepetitionAndDuration">
                        <ngb-timepicker
                          name="timerDuration"
                          [spinners]="false"
                          [seconds]="true"
                          [(ngModel)]="newProgression.timeDuration"
                          required
                          #duration1="ngModel"
                        ></ngb-timepicker>
                      </ng-template>
                      <ng-template [ngIf]="!switchRepetitionAndDuration">
                        <input
                          type="text"
                          autocomplete="off"
                          id="pra"
                          class="form-control"
                          name="pra"
                          [(ngModel)]="newProgression.repetitions"
                          #ipra="ngModel"
                          maxlength="11"
                        />
                      </ng-template>
                    </div>
                  </div>
                </div>
                <div
                  class="row mb-2"
                  *ngIf="isAddingProgression || isEditingProgression"
                >
                  <div class="col-sm">
                    <div class="form-group">
                      <label for="pc"
                        >Carga
                        <i
                          class="fa fa-question-circle"
                          style="cursor: pointer"
                          role="button"
                          tabindex="0"
                          placement="top"
                          [ngbTooltip]="tipCarProg"
                        ></i
                      ></label>
                      <input
                        type="text"
                        autocomplete="off"
                        id="pc"
                        class="form-control"
                        mask="9*"
                        name="pc"
                        [(ngModel)]="newProgression.load"
                        #ipc="ngModel"
                      />
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <label for="pv"
                        >Velocidade
                        <i
                          class="fa fa-question-circle"
                          style="cursor: pointer"
                          role="button"
                          tabindex="0"
                          placement="top"
                          [ngbTooltip]="tipVelProg"
                        ></i
                      ></label>
                      <ng-select
                        [items]="velocidade"
                        [notFoundText]="'Nenhuma velocidade encontrada'"
                        name="pv"
                        id="pv"
                        [(ngModel)]="newProgression.speed"
                      ></ng-select>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="form-group">
                      <label for="pd"
                        >Descanso
                        <i
                          class="fa fa-question-circle"
                          style="cursor: pointer"
                          role="button"
                          tabindex="0"
                          placement="top"
                          [ngbTooltip]="tipDesProg"
                        ></i
                      ></label>
                      <input
                        type="text"
                        autocomplete="off"
                        id="pd"
                        class="form-control"
                        mask="9*"
                        name="pd"
                        [(ngModel)]="newProgression.breakTime"
                        #ipd="ngModel"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="row mb-2"
                  *ngIf="isAddingProgression || isEditingProgression"
                >
                  <div class="col-sm">
                    <div class="form-group">
                      <label for="pn"
                        >Observação
                        <i
                          class="fa fa-question-circle"
                          style="cursor: pointer"
                          role="button"
                          tabindex="0"
                          placement="top"
                          [ngbTooltip]="tipNotProg"
                        ></i
                      ></label>
                      <textarea
                        id="pn"
                        rows="3"
                        class="form-control"
                        name="pn"
                        maxlength="500"
                        [(ngModel)]="newProgression.note"
                        #inputObs="ngModel"
                        placeholder="Você pode escrever observações sobre este treino"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-sm" style="align-self: center;">
                    <button type="button" class="btn btn-block gradient-mint btn-icon" style="margin-top: 7px" [disabled]="!verifyNewProgression()" (click)="isEditingProgression ? atualizaProgressao() : cadastraProgressao()"><i class="fa fa-save"></i></button>
                  </div> -->

                <div
                  class="row mb-2"
                  *ngIf="isAddingProgression || isEditingProgression"
                >
                  <div class="col-sm" style="align-self: center">
                    <button
                      type="button"
                      class="btn btn-block btn-outline-danger btn-icon"
                      (click)="cancelaCadastroProgressao()"
                      style="margin-top: 7px"
                    >
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="col-sm" style="align-self: center"></div>
                  <div class="col-sm" style="align-self: center">
                    <button
                      type="button"
                      class="btn btn-block gradient-mint btn-icon"
                      style="margin-top: 7px"
                      [disabled]="!verifyNewProgression()"
                      (click)="
                        isEditingProgression
                          ? atualizaProgressao()
                          : cadastraProgressao()
                      "
                    >
                      <i class="fa fa-save"></i>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <thead class="thead-dark">
                          <tr>
                            <ng-template #tipExecProg
                              >Execução inicial que vai começar esta
                              periodização. Informe obrigatoriamente uma
                              execução maior que 1.</ng-template
                            >
                            <ng-template #tipDataProg
                              >Data inicial que vai começar esta
                              periodização.</ng-template
                            >
                            <ng-template #tipDurProg>{{
                              switchRepetitionAndDuration
                                ? "Novo tempo de duração em segundos"
                                : "Nova quantidade de repetições"
                            }}</ng-template>
                            <ng-template #tipCarProg
                              >Novo valor de carga total.</ng-template
                            >
                            <ng-template #tipVelProg
                              >Novo valor de velocidade.</ng-template
                            >
                            <ng-template #tipDesProg
                              >Novo tempo de descanso.</ng-template
                            >
                            <ng-template #tipNotProg
                              >Novo valor da observação.</ng-template
                            >
                            <ng-template #tipSerProg
                              >Nova quantidade de séries.</ng-template
                            >
                            <ng-template #tipStatProg
                              >Status indicando se a periodização já foi
                              realizada ou não.</ng-template
                            >
                            <th class="text-center p-2">
                              execução inicial
                              <i
                                class="fa fa-question-circle"
                                style="cursor: pointer"
                                role="button"
                                tabindex="0"
                                placement="top"
                                [ngbTooltip]="tipExecProg"
                              ></i>
                            </th>
                            <th class="text-center p-2">
                              data inicial
                              <i
                                class="fa fa-question-circle"
                                style="cursor: pointer"
                                role="button"
                                tabindex="0"
                                placement="top"
                                [ngbTooltip]="tipDataProg"
                              ></i>
                            </th>
                            <th
                              class="text-center p-2"
                              *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                            >
                              série
                              <i
                                class="fa fa-question-circle"
                                style="cursor: pointer"
                                role="button"
                                tabindex="0"
                                placement="top"
                                [ngbTooltip]="tipSerProg"
                              ></i>
                            </th>
                            <th class="text-center p-2">
                              {{
                                switchRepetitionAndDuration
                                  ? "duração"
                                  : "repetições"
                              }}
                              <i
                                class="fa fa-question-circle"
                                style="cursor: pointer"
                                role="button"
                                tabindex="0"
                                placement="top"
                                [ngbTooltip]="tipDurProg"
                              ></i>
                            </th>
                            <th class="text-center p-2">
                              carga total
                              <i
                                class="fa fa-question-circle"
                                style="cursor: pointer"
                                role="button"
                                tabindex="0"
                                placement="top"
                                [ngbTooltip]="tipCarProg"
                              ></i>
                            </th>
                            <th class="text-center p-2">
                              velocidade
                              <i
                                class="fa fa-question-circle"
                                style="cursor: pointer"
                                role="button"
                                tabindex="0"
                                placement="top"
                                [ngbTooltip]="tipVelProg"
                              ></i>
                            </th>
                            <th class="text-center p-2">
                              descanso
                              <i
                                class="fa fa-question-circle"
                                style="cursor: pointer"
                                role="button"
                                tabindex="0"
                                placement="top"
                                [ngbTooltip]="tipDesProg"
                              ></i>
                            </th>
                            <th class="text-center p-2">
                              observação
                              <i
                                class="fa fa-question-circle"
                                style="cursor: pointer"
                                role="button"
                                tabindex="0"
                                placement="top"
                                [ngbTooltip]="tipNotProg"
                              ></i>
                            </th>
                            <th class="text-center p-2">
                              status
                              <i
                                class="fa fa-question-circle"
                                style="cursor: pointer"
                                role="button"
                                tabindex="0"
                                placement="top"
                                [ngbTooltip]="tipStatProg"
                              ></i>
                            </th>
                            <th class="text-center p-2">#</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let progression of progressions">
                            <td class="text-center p-2">
                              {{
                                progression.execution
                                  ? progression.execution
                                  : "--"
                              }}
                            </td>
                            <td class="text-center p-2">
                              {{
                                progression.progressionDate
                                  ? formatDateBrazil(
                                      progression.progressionDate
                                    )
                                  : "--"
                              }}
                            </td>
                            <td
                              class="text-center p-2"
                              *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                            >
                              {{
                                progression.series ? progression.series : "--"
                              }}
                            </td>
                            <td class="text-center p-2">
                              {{
                                switchRepetitionAndDuration
                                  ? progression.duration
                                    ? progression.duration
                                    : "--"
                                  : progression.repetitions
                                  ? progression.repetitions
                                  : "--"
                              }}
                            </td>
                            <td class="text-center p-2">
                              {{ progression.load ? progression.load : "--" }}
                            </td>
                            <td class="text-center p-2">
                              {{ progression.speed ? progression.speed : "--" }}
                            </td>
                            <td class="text-center p-2">
                              {{
                                progression.breakTime
                                  ? progression.breakTime
                                  : "--"
                              }}
                            </td>
                            <td class="text-center p-2">
                              {{ progression.note ? progression.note : "--" }}
                            </td>
                            <td class="text-center p-2">
                              {{
                                progression.status ? "Realizada" : "Pendente"
                              }}
                            </td>
                            <td class="text-center p-2">
                              <div
                                class="btn-group btn-group-sm pl-0 pr-0"
                                role="group"
                              >
                                <button
                                  type="button"
                                  [disabled]="progression.status"
                                  (click)="editProgression(progression)"
                                  class="btn btm-sm gradient-mint btn-icon round"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button
                                  type="button"
                                  [disabled]="progression.status"
                                  (click)="deleteProgression(progression)"
                                  class="btn btm-sm btn-danger btn-icon round"
                                >
                                  <i class="fa fa-trash"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr *ngIf="!progressions || progressions.length == 0">
                            <td colspan="9">
                              <p class="text-center">
                                Nenhuma periodização cadastrada
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template [ngIf]="showFeedback">
                <div class="form-row">
                  <div class="col-md-4 col-12">
                    <div class="form-group mb-2">
                      <label for="loadFeedback">Feedback Carga Total</label>
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          id="loadFeedback"
                          class="form-control"
                          name="loadFeedback"
                          [disabled]="true"
                          [value]="
                            exercicie.loadFeedback != null
                              ? exercicie.loadFeedback
                              : 'N/A'
                          "
                          aria-describedby="group-loadFeedback"
                        />
                        <div
                          class="input-group-append"
                          *ngIf="exercicie.loadFeedback"
                        >
                          <span class="input-group-text" id="group-loadFeedback"
                            >KG</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group mb-2">
                      <label for="repetitionFeedback"
                        >Feedback Repetições</label
                      >
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          id="repetitionFeedback"
                          class="form-control"
                          name="repetitionFeedback"
                          [disabled]="true"
                          [value]="
                            exercicie.repetitionFeedback != null
                              ? exercicie.repetitionFeedback
                              : 'N/A'
                          "
                          aria-describedby="group-repetitionFeedback"
                        />
                        <div
                          class="input-group-append"
                          *ngIf="exercicie.repetitionFeedback"
                        >
                          <span
                            class="input-group-text"
                            id="group-repetitionFeedback"
                            >X</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group mb-2">
                      <label for="timeFeedback">Feedback Execução</label>
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          id="timeFeedback"
                          class="form-control"
                          name="timeFeedback"
                          [disabled]="true"
                          [value]="
                            exercicie.timeFeedback != null
                              ? exercicie.timeFeedback
                              : 'N/A'
                          "
                          aria-describedby="group-timeFeedback"
                        />
                        <div
                          class="input-group-append"
                          *ngIf="exercicie.timeFeedback"
                        >
                          <span class="input-group-text" id="group-timeFeedback"
                            >Seg</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template [ngIf]="isEditingExercise">
                <div class="form-row mb-2">
                  <div class="col-md-6 col-12">
                    <button
                      type="button"
                      class="btn btn-lg btn-light float-left"
                      awPreviousStep
                    >
                      Voltar
                    </button>
                  </div>
                  <div class="col-md-6 col-12">
                    <button
                      type="button"
                      *ngIf="verificaExercicio(null)"
                      class="btn btn-lg btn-warning float-right"
                      [disabled]="!verificaPreenchimentoExercicio()"
                      (click)="atualizarExercicio(modal)"
                    >
                      <i class="fa fa-save"></i> Editar Exercício
                    </button>
                  </div>
                </div>
              </ng-template>
              <ng-template
                [ngIf]="
                  !isEditingExercise && !verificaTreinoDeAvaliacaoPreenchido()
                "
              >
                <div class="form-row mb-2">
                  <div class="col-md-6 col-12">
                    <button
                      type="button"
                      class="btn btn-lg btn-light float-left"
                      awPreviousStep
                    >
                      Voltar
                    </button>
                  </div>
                  <div class="col-md-6 col-12">
                    <button
                      type="button"
                      class="btn btn-lg btn-warning float-right"
                      [disabled]="!verificaPreenchimentoExercicio()"
                      (click)="adicionarExercicio(modal)"
                    >
                      <i class="fa fa-save"></i> Adicionar Exercício
                    </button>
                  </div>
                </div>
              </ng-template>
            </form>
          </aw-wizard-step>
        </aw-wizard>
      </div>
    </div>
  </div></ng-template
>

<ng-template #modalEditConjugado let-modal>
  <div class="card">
    <div class="card-header">
      <div class="row mb-0">
        <div class="col">
          <h4 class="card-title float-left">
            {{ conjugadosToAdmin.name ? conjugadosToAdmin.name : "Conjugados" }}
          </h4>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn btn-outline-danger btn-icon float-right"
            (click)="modal.close('Close click')"
          >
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <div class="row mb-0">
        <div class="col">
          <p>Edite os exercícios deste conjunto</p>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="row" *ngIf="conjugadosToAdmin.list.length > 0">
          <div class="col">
            <ul
              class="list-group mb-2 draglist"
              cdkDropList
              (cdkDropListDropped)="dropConjugado($event)"
            >
              <li
                *ngFor="let exercicio of conjugadosToAdmin.list"
                cdkDrag
                class="list-group-item"
              >
                <div class="media">
                  <span class="dot align-middle mr-2">{{
                    conjugadosToAdmin.list.indexOf(exercicio) + 1
                  }}</span>
                  <div class="media-body">
                    <h5 class="mt-0">{{ exercicio.name }}</h5>
                    <span>{{ getLabelCategory(exercicio.category) }}</span>
                  </div>
                  <div class="d-none d-md-block">
                    <div
                      class="btn-group btn-group-lg float-right"
                      role="group"
                    >
                      <button
                        type="button"
                        class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
                        placement="left"
                        role="button"
                        tabindex="0"
                        [ngbTooltip]="
                          tipoSelecionado &&
                          tipoSelecionado.id == 4 &&
                          training &&
                          training.TrainingExecution &&
                          training.TrainingExecution.length > 0
                            ? 'Visualizar exercício'
                            : 'Editar exercício.'
                        "
                        (click)="
                          editarExercicio(exercicio, modalExercicio, modal)
                        "
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                    </div>
                  </div>
                  <div class="d-md-none" role="group">
                    <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                      <button
                        type="button"
                        class="btn btn-outline-warning btn-sm dropdown-toggle"
                        ngbDropdownToggle
                      >
                        <i class="ft-more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu class="dropdown-menu" role="menu">
                        <a
                          class="dropdown-item"
                          (click)="
                            editarExercicio(exercicio, modalExercicio, modal)
                          "
                          >{{
                            tipoSelecionado &&
                            tipoSelecionado.id == 4 &&
                            training &&
                            training.TrainingExecution &&
                            training.TrainingExecution.length > 0
                              ? "Visualizar exercício"
                              : "Editar exercício."
                          }}</a
                        >
                        <a
                          class="dropdown-item"
                          (click)="removerExercicio(exercicio)"
                          *ngIf="verificaExercicio(exercicio)"
                          >Remover exercício</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mb-2 mt-2 ml-3">
                    <i
                      class="fa fa-reorder mr-2 text-muted"
                      cdkDragHandle
                      style="font-size: 25px; cursor: pointer"
                      placement="left"
                      ngbTooltip="Arraste para reordenar este exercício."
                    ></i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row mb-0">
        <div class="col text-center">
          <p
            class="text-danger"
            (click)="modal.close('Close click'); openAssociar(modalConjugados)"
          >
            Para adicionar, remover ou substituir exercícios dentro deste
            conjunto <a><b>clique em gerenciar conjuntos</b></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalConjugados let-modal>
  <div class="card">
    <div class="card-header">
      <div class="row mb-0">
        <div class="col">
          <h4 class="card-title float-left">Gerenciar Conjuntos</h4>
        </div>
        <div class="col">
          <button
            type="button"
            class="btn btn-outline-danger btn-icon float-right"
            (click)="modal.close('Close click')"
          >
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p class="float-left">
            Associe diferentes exercícios para criar conjuntos biset e triset
          </p>
        </div>
      </div>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="row mb-2" *ngIf="addingOrUpdatingConjugado">
          <div class="col">
            <div class="form-group mb-2 scrollable-box-1">
              <label for="trainings"
                >Selecione exercícios para serem incluídos neste conjunto</label
              >
              <ng-select
                [appendTo]="'.scrollable-box-1'"
                [notFoundText]="'Nenhum exercício encontrado'"
                [items]="opcoesParaConjugar"
                bindLabel="name"
                name="trainings"
                placeholder="Selecionar Treinos"
                id="trainings"
                [(ngModel)]="conjugadoAddingOrEditing"
                multiple="true"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{
                    (conjugadoAddingOrEditing.indexOf(item) + 1).toString() +
                      " - " +
                      item.name
                  }}</span>
                </ng-template>
                <ng-template
                  ng-option-tmp
                  let-item="item"
                  let-index="index"
                  let-search="searchTerm"
                >
                  <span>{{
                    conjugadoAddingOrEditing.indexOf(item) > -1
                      ? (
                          conjugadoAddingOrEditing.indexOf(item) + 1
                        ).toString() +
                        " - " +
                        item.name
                      : item.name
                  }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row mb-2" *ngIf="addingOrUpdatingConjugado">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-danger mb-md-0 float-left"
              (click)="cancelaAddOuEditConjugado()"
            >
              <i class="fa fa-close"></i> Cancelar
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              [disabled]="
                !conjugadoAddingOrEditing || conjugadoAddingOrEditing.length < 2
              "
              class="btn btn-lg btn-warning mb-md-0 float-right"
              (click)="salvarConjugado()"
            >
              <i class="fa fa-save"></i> Salvar Conjunto
            </button>
          </div>
        </div>
        <div class="row mb-2" *ngIf="!addingOrUpdatingConjugado">
          <div class="col">
            <button
              type="button"
              class="btn btn-lg btn-warning mb-md-0 float-right"
              (click)="liberaAddConjugado()"
            >
              <i class="fa fa-plus"></i> Adicionar Conjunto
            </button>
          </div>
        </div>
        <div class="row" *ngIf="conjugados.length > 0">
          <div class="col">
            <ul class="list-group mb-2">
              <li *ngFor="let c of conjugados" class="list-group-item">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0">
                      Conjunto {{ conjugados.indexOf(c) + 1 }}
                    </h5>
                    <span>{{ getLabelAssociados(c.list) }}</span>
                  </div>
                  <div class="btn-group btn-group-lg" role="group">
                    <button
                      type="button"
                      class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
                      placement="left"
                      ngbTooltip="Clique para editar este conjunto."
                      (click)="liberaEditConjugado(c)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
                      placement="left"
                      ngbTooltip="Clique para remover este conjunto."
                      (click)="deletarConjugado(c)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row" *ngIf="conjugados.length == 0">
          <div class="col">
            <ul class="list-group mb-2">
              <li class="list-group-item">
                <div class="media">
                  <div class="media-body">
                    <h5 class="mt-0">Nenhum exercício associado.</h5>
                    <span>Adicione um novo conjunto!</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentVideo>
  <ng-template
    [ngIf]="
      urlToPlay.startsWith('https://www.youtube.com/') ||
      urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <div class="embed-container">
      <iframe [src]="safeURL" frameborder="0" allowfullscreen></iframe>
    </div>
  </ng-template>
  <ng-template
    [ngIf]="
      !urlToPlay.startsWith('https://www.youtube.com/') &&
      !urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <video controls style="width: 100%">
      <source [src]="urlToPlay" type="video/mp4" />
    </video>
  </ng-template>
</ng-template>
<ng-template #contentGif>
  <img style="width: 100%" [src]="urlToPlay" />
</ng-template>

<ng-template #contentTutoriais let-modal>
  <div class="card-header">
    <h4 class="card-title">Tutoriais</h4>
  </div>
  <button class="close-button" (click)="modal.close('Close click')">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <div class="form-row" style="margin: 5px">
    <ul class="list-group mb-2" style="width: 100%">
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">1</span>
          <div class="media-body">
            <h5 class="mt-0">Como adicionar um novo treino</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=iZ5Ci7O8ols',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=iZ5Ci7O8ols',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">2</span>
          <div class="media-body">
            <h5 class="mt-0">Como montar circuitos e intervalados</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=AQ1g_qVnVfg',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=AQ1g_qVnVfg',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">3</span>
          <div class="media-body">
            <h5 class="mt-0">Como criar treino avaliativo</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=FVtWmcRTRok',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=FVtWmcRTRok',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">4</span>
          <div class="media-body">
            <h5 class="mt-0">Periodização automatizada</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=brg5wo1VHjA',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=brg5wo1VHjA',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">5</span>
          <div class="media-body">
            <h5 class="mt-0">Biset/triset</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=oc5Ek3sjcxk',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidiaTutorial(
                  'https://www.youtube.com/watch?v=oc5Ek3sjcxk',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

<ngx-spinner></ngx-spinner>

<!-- <div class="row">
  <div class="col-12">
    <span class="d-block text-center">
      <b>Parabéns!</b> <br />
      Você criou seu primeiro treino! <b>Baixe o app do aluno</b> para ver
      através da perspectiva do aluno.
    </span>
  </div>
  <div class="col-12 mt-3">
    <div class="w-100 d-flex flex-column align-items-start">
      <div
        class="w-100 d-flex flex-column align-items-start justify-content-start download-app__credential-field"
      >
        <div class="d-flex justify-content-start align-items-center">
          <span class="d-block text-bold-600 text-left font-size-5">
            Email:
          </span>
          <button
            id="copy-customer-email"
            type="button"
            title="Copiar email"
            class="btn btn-sm btn-outline-secondary py-0 border-0 ml-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              data-name="Layer 1"
              id="Layer_1"
            >
              <title />
              <path
                d="M27.2,8.22H23.78V5.42A3.42,3.42,0,0,0,20.36,2H5.42A3.42,3.42,0,0,0,2,5.42V20.36a3.43,3.43,0,0,0,3.42,3.42h2.8V27.2A2.81,2.81,0,0,0,11,30H27.2A2.81,2.81,0,0,0,30,27.2V11A2.81,2.81,0,0,0,27.2,8.22ZM5.42,21.91a1.55,1.55,0,0,1-1.55-1.55V5.42A1.54,1.54,0,0,1,5.42,3.87H20.36a1.55,1.55,0,0,1,1.55,1.55v2.8H11A2.81,2.81,0,0,0,8.22,11V21.91ZM28.13,27.2a.93.93,0,0,1-.93.93H11a.93.93,0,0,1-.93-.93V11a.93.93,0,0,1,.93-.93H27.2a.93.93,0,0,1,.93.93Z"
              />
            </svg>
          </button>
        </div>
        <div class="w-100 d-flex mt-2">
          <div
            class="w-100 px-3 py-1 border bg-white d-flex justify-content-start rounded-pill shadow-sm"
          >
            <span class="font-size-5 truncate"> ${email} </span>
          </div>
        </div>
      </div>
      <div
        class="w-100 d-flex flex-column align-items-start justify-content-start download-app__credential-field mt-3"
      >
        <div class="d-flex justify-content-start align-items-center">
          <span class="d-block text-bold-600 text-left font-size-5">
            Senha:
          </span>
          <button
            id="copy-customer-password"
            type="button"
            title="Copiar senha"
            class="btn btn-sm btn-outline-secondary py-0 border-0 ml-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              data-name="Layer 1"
            >
              <title />
              <path
                d="M27.2,8.22H23.78V5.42A3.42,3.42,0,0,0,20.36,2H5.42A3.42,3.42,0,0,0,2,5.42V20.36a3.43,3.43,0,0,0,3.42,3.42h2.8V27.2A2.81,2.81,0,0,0,11,30H27.2A2.81,2.81,0,0,0,30,27.2V11A2.81,2.81,0,0,0,27.2,8.22ZM5.42,21.91a1.55,1.55,0,0,1-1.55-1.55V5.42A1.54,1.54,0,0,1,5.42,3.87H20.36a1.55,1.55,0,0,1,1.55,1.55v2.8H11A2.81,2.81,0,0,0,8.22,11V21.91ZM28.13,27.2a.93.93,0,0,1-.93.93H11a.93.93,0,0,1-.93-.93V11a.93.93,0,0,1,.93-.93H27.2a.93.93,0,0,1,.93.93Z"
              />
            </svg>
          </button>
        </div>
        <div class="w-100 d-flex mt-2">
          <div
            class="w-100 px-3 py-1 border bg-white d-flex justify-content-start rounded-pill shadow-sm"
          >
            <span class="font-size-5 truncate"> ${password} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="d-flex justify-content-center">
      <a
        href="https://play.google.com/store/apps/details?id=br.com.personaldigital"
        target="_blank"
        rel="noopener noreferrer"
        class="w-100 btn btn-store-download shadow-none d-flex justify-content-center align-items-center px-3 py-1"
      >
        <img
          src="../../assets/img/play_store_logo.svg"
          alt="Play Store"
          class="store-download-icon play-store"
        />
        <div class="d-flex flex-column align-items-start ml-2">
          <span class="font-size-3">Download na</span>
          <span class="font-size-4 text-bold-600">Google Play</span>
        </div>
      </a>
    </div>
    <div class="mt-2 d-flex justify-content-center">
      <a
        href="https://apps.apple.com/br/app/o-personal-digital/id1532310171"
        target="_blank"
        rel="noopener noreferrer"
        class="w-100 btn btn-store-download shadow-none d-flex justify-content-center align-items-center px-3 py-1"
      >
        <img
          src="../../assets/img/apple_logo.svg"
          alt="App Store"
          class="store-download-icon"
        />
        <div class="d-flex flex-column align-items-start ml-2">
          <span class="font-size-3">Download na</span>
          <span class="font-size-4 text-bold-600">App Store</span>
        </div>
      </a>
    </div>
  </div>
</div> -->
