import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CustomAdapter } from "app/shared/services/dateAdapter.service";
import { CustomDateParserFormatter } from "app/shared/services/dateParserFormatter.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, take } from "rxjs/operators";
import Api from "../../../../helpers/api";
import App from "../../../../helpers/app";
import Me from "../../../../helpers/me";
import { PlanStatusType } from "../treinos-edit/treinos-edit.types";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-treinos-list",
  templateUrl: "./treinos-list.component.html",
  styleUrls: ["./treinos-list.component.scss"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class TreinosListComponent implements OnInit {
  apiCall = this.api.new().silent();
  pageTr = 1;
  pageSize = 10;
  trainings = [];
  trainingsFiltered = [];
  relations = [];
  relationsFiltered = [];
  folders = [];
  folderFiltered = null;
  groups = [];
  grupoSelecionado;
  scheduledDate: string;
  dueDate: string;
  minScheduledDate = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date(),
  };
  groupsTrainings = null;
  pageEx = 1;
  pageSizeExec = 5;
  treinoProcurado = null;
  programaDesafioSelecionado = null;
  conjugados = [];
  tipos = [
    { id: 1, name: "Personalizado" },
    { id: 2, name: "Circuito" },
    { id: 3, name: "Intervalado" },
    { id: 4, name: "Avaliação" },
  ];
  user = null;
  urlToPlay = null;
  safeURL = null;
  planStatus: PlanStatusType | null = null;
  totalTrainings = 0; // Total de registros
  offset = 0;
  limit = 10;

  dueDateError = false;

  searchSubject = new Subject<string>();

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday());
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private me: Me,
    private _sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private app: App,
    private api: Api,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {
    this.folderFiltered = null;
    this.treinoProcurado = null;
  }

  async ngOnInit() {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.searchSubject
      .pipe(
        debounceTime(300), // Aguarda 300ms após o usuário parar de digitar
        distinctUntilChanged() // Apenas executa se o valor for diferente do anterior
      )
      .subscribe((searchTerm) => {
        console.log("searchTerm:", searchTerm);
        if (searchTerm.length >= 3 || searchTerm.length === 0) {
          // Chama a função de carregar localizações com o novo termo
          this.fetchPaginatedTrainings(0, this.pageSize);
        }
      });

    this.apiCall
      .get<PlanStatusType>("seller/me/verify/subscription/iago")
      .subscribe((response) => {
        if (response.success) {
          this.planStatus = response.return;
        }
      });
    await this.fetchPaginatedTrainings(this.offset, this.limit);

    self.apiCall.get("training/folders").subscribe(async (data) => {
      if (data.return) self.folders = data.return;
    });

    self.apiCall.get("sellercustomer").subscribe(async (data) => {
      if (data.return) this.relations = data.return.relations;
    });

    const groups = await self.apiCall
      .get("groupcustomers")
      .pipe(take(1))
      .toPromise();
    if (groups.return) self.groups = groups.return;

    self.apiCall.get("grouptrainingall").subscribe(async (cat) => {
      if (cat.return) self.groupsTrainings = cat.return;
      self.spinner.hide();
    });

    this.me
      .get()
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
      });
  }
  async fetchPaginatedTrainings(offset: number = 0, limit: number = 10) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let query = `v2/training/personal/0?offset=${offset}&limit=${limit}`;
    if (this.treinoProcurado) {
      query += `&search=${this.treinoProcurado}`;
    }
    if (this.folderFiltered && this.folderFiltered.id) {
      query += `&trainingFolderId=${this.folderFiltered.id}`;
    }

    this.apiCall.get(query).subscribe(
      (data) => {
        if (data.return && data.return.rows) {
          // Ordenar pelo ID do menor para o maior
          if (this.folderFiltered) {
            data.return.rows = data.return.rows.sort((a, b) => {
              const folderIdA = a.TrainingFolderTrainings?.[0]?.id || 0;
              const folderIdB = b.TrainingFolderTrainings?.[0]?.id || 0;
              return folderIdA - folderIdB;
            });
          }

          this.trainings = data.return.rows;
          this.trainingsFiltered = data.return.rows;
          this.totalTrainings = data.return.count;
        }
        this.spinner.hide();
      },
      (error) => {
        console.error("Erro ao carregar os treinamentos:", error);
        this.spinner.hide();
      }
    );
  }

  onPageChange(page: number) {
    this.offset = (page - 1) * this.pageSize;
    this.fetchPaginatedTrainings(this.offset, this.pageSize);
  }

  getNameOfTypeTraing(type) {
    return this.tipos.find((t) => t.id == type).name;
  }
  formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }
  listaExercicios = [];

  getNameExercise(e) {
    if (!e.conjugado) {
      return "";
    }
    let retorno = "";
    const searching = this.listaExercicios.find((x) => x.id == e.conjugado);
    if (searching) {
      retorno = "Conjugado com " + searching.name;
    }
    return retorno;
  }
  typeTrainingInView = null;
  viewExercicios(training, content) {
    this.typeTrainingInView = training.type;
    this.listaExercicios = training.TrainingExercises.sort(function (a, b) {
      return a.order - b.order;
    });

    this.modalService.open(content, { centered: true, size: "lg" });
  }
  nomePasta;
  treinosSelecionadosPasta = [];
  pastasSelecionadasTreino = [];
  folderForUpdate = {
    id: null,
    folder: null,
  };

  foldersOfTreino = [];
  trainingUpdateFolder;

  async openCadastroFolder(modal) {
    this.nomePasta;
    this.treinosSelecionadosPasta = [];
    this.modalService.open(modal, { centered: true });
  }
  agendamentoParaExportar;
  vencimentoParaExportar: string;
  treinosSelecionadosParaExportar = [];
  async exportarPastaTreinos(modal) {
    this.grupoSelecionado = null;
    this.agendamentoParaExportar = this.today;
    this.vencimentoParaExportar = null;
    this.treinosSelecionadosParaExportar = [];
    this.alunosSelecionadosParaExportar = [];
    this.relationsFiltered = [...this.relations];
    if (this.folderFiltered && this.folderFiltered.TrainingFolderTrainings) {
      for (let t of this.folderFiltered.TrainingFolderTrainings) {
        const found = this.trainings.find((x) => x.id == t.training);
        if (found) this.treinosSelecionadosParaExportar.push(found);
      }
    }
    this.modalService.open(modal, { centered: true });
  }
  async openFoldersOfTreino(modal, training) {
    this.trainingUpdateFolder = null;
    if (training && training.TrainingFolderTrainings) {
      this.trainingUpdateFolder = { ...training };
      this.foldersOfTreino = [];
      for (let t of training.TrainingFolderTrainings) {
        const found = this.folders.find((x) => x.id == t.folder);
        if (found) {
          this.foldersOfTreino.push(found);
        }
      }
      this.modalService.open(modal, { centered: true });
    }
  }
  async cancelaExport(modal) {
    this.grupoSelecionado = null;
    this.treinosSelecionadosParaExportar = [];
    this.alunosSelecionadosParaExportar = [];
    modal.close("Close click");
  }
  async cancelaPasta(modal) {
    this.nomePasta;
    this.treinosSelecionadosPasta = [];
    this.foldersOfTreino = [];
    this.folderForUpdate = {
      id: null,
      folder: null,
    };
    modal.close("Close click");
  }
  async atualizarPasta(modal) {
    const self = this;
    if (
      self.folderForUpdate &&
      self.folderForUpdate.id &&
      self.folderForUpdate.folder
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .put("training/folders", {
          TrainingFolder: {
            id: self.folderForUpdate.id,
            folder: self.folderForUpdate.folder,
          },
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaPasta(modal);
            self.ngOnInit();
            await this.app.alert(
              "Tudo certo!",
              "Pasta atualizada com sucesso",
              "success"
            );
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }
  async atualizarPastasTreino(modal) {
    const self = this;
    if (
      self.trainingUpdateFolder &&
      self.foldersOfTreino &&
      self.foldersOfTreino.length > 0
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .put("training/folders/update", {
          training: self.trainingUpdateFolder,
          folders: self.foldersOfTreino,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaPasta(modal);
            self.ngOnInit();
            await this.app.alert(
              "Tudo certo!",
              "Pastas atualizadas com sucesso",
              "success"
            );
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }
  async cadastrarPasta(modal) {
    const self = this;
    if (
      self.nomePasta &&
      self.treinosSelecionadosPasta &&
      self.treinosSelecionadosPasta.length > 0
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("training/folders", {
          TrainingFolder: {
            folder: self.nomePasta,
            TrainingFolderTrainings: self.treinosSelecionadosPasta,
          },
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaPasta(modal);
            self.ngOnInit();
            await this.app.alert(
              "Tudo certo!",
              "Pasta cadastrada com sucesso",
              "success"
            );
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }
  onDeleteFolder(folder) {
    const self = this;
    self.app
      .confirm("Tem certeza?", "Você realmente deseja deletar esta pasta?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("training/folders/" + folder.id)
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Pasta deletada com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  onEditFolder(folder, modal) {
    this.folderForUpdate = { ...folder };
    this.modalService.open(modal, { centered: true, size: "lg" });
  }

  onSearchTreino() {
    this.searchSubject.next(this.treinoProcurado);
  }
  onResetTreino() {
    this.trainingsFiltered = this.trainings;
  }
  onFilterFolder() {
    this.pageTr = 1; // Reseta para a primeira página ao aplicar filtro
    this.fetchPaginatedTrainings(0, this.pageSize);
  }

  openTrainingType(modalInstance: any, callback: Function) {
    modalInstance.close();
    callback;
  }

  OpenPersonalGPTModal(content: any) {
    if (this.planStatus.plano_ativo && this.planStatus.plano_em_dia) {
      const beforeDismiss = async () => {
        return await this.app
          .confirm(
            "Atenção",
            "Você tem certeza que deseja sair? As alterações não salvas serão perdidas!"
          )
          .then((condition) => !!condition);
      };

      this.modalService.open(content, {
        centered: true,
        size: "xl",
        beforeDismiss,
      });
    } else {
      window.open("https://opersonaldigital.com.br/pv-iago/", "_blank");
    }
  }

  openCreationTypeModal(
    training: any = null,
    isExperimental: boolean,
    content: any
  ) {
    if (this.user) {
      this.modalService.open(content, { centered: true });
    } else {
      this.openTreino(training, isExperimental);
    }
  }

  openTreino(training: any = null, isExperimental: boolean) {
    const data = {
      training: training && training.id ? training.id : null,
      canEdit: true,
      isModel: true,
      isExperimental: isExperimental,
    };

    const encodedData = btoa(JSON.stringify(data))
      .replace("==", "")
      .replace("=", "");

    if (this.user && training && training?.editorVersion === "iago_v1") {
      this.router.navigate([`/page/personal/treinos/${encodedData}/editar`]);
    } else {
      this.router.navigate(["/page/treino/details/" + encodedData]);
    }
  }

  async cancelaExportacaoAlunos(modal) {
    this.grupoSelecionado = null;
    this.scheduledDate = this.today;
    this.dueDate = "";
    this.dueDateError = false;
    this.exportandoAlunos = false;
    this.trainingToExport = null;
    this.alunosSelecionadosParaExportar = [];
    modal.close("Close click");
  }

  alunosSelecionadosParaExportar = [];
  trainingToExport = null;
  exportandoAlunos = false;
  async exportarTreinoAluno(training, modal) {
    this.grupoSelecionado = null;
    this.exportandoAlunos = true;
    this.trainingToExport = training;
    this.relationsFiltered = this.relations;
    this.modalService.open(modal, { centered: true });
  }
  copiarAlunosGrupoExpTreino() {
    if (!this.grupoSelecionado || !this.grupoSelecionado.CustomersGroupsUsers) {
      return;
    }
    for (let t of this.grupoSelecionado.CustomersGroupsUsers) {
      const found = this.relations.find((x) => x.id == t.relation);
      if (found) {
        let verify = null;
        if (
          this.alunosSelecionadosParaExportar &&
          this.alunosSelecionadosParaExportar.length > 0
        ) {
          this.alunosSelecionadosParaExportar.find((x) => x.id == found.id);
        }
        if (!verify) {
          this.alunosSelecionadosParaExportar.push(found);
        }
      }
    }
    this.alunosSelecionadosParaExportar = [
      ...this.alunosSelecionadosParaExportar,
    ];
  }

  async exportarPastaAlunos(modal) {
    const self = this;
    if (self.alunosSelecionadosParaExportar.length > 5) {
      const confirmacao = await self.app.confirm(
        "Alerta!",
        "A Exportação de pastas de treinos para mais de 5 alunos é agendada e pode levar até 1h para ser concluída! Caso precise exportar imediatamente exporte para até 5 alunos por vez, deseja continuar?"
      );
      if (!confirmacao) {
        return;
      }
    }
    if (
      self.agendamentoParaExportar &&
      self.treinosSelecionadosParaExportar &&
      self.treinosSelecionadosParaExportar.length > 0 &&
      self.alunosSelecionadosParaExportar &&
      self.alunosSelecionadosParaExportar.length > 0
    ) {
      let agendamento = moment(
        self.agendamentoParaExportar,
        "DDMMYYYY"
      ).toDate();
      let vencimento = self.vencimentoParaExportar
        ? moment(self.vencimentoParaExportar, "DD/MM/YYYY").toDate()
        : null;
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("training/export/folder/customer", {
          trainings: self.treinosSelecionadosParaExportar,
          relations: self.alunosSelecionadosParaExportar,
          agendamento: agendamento,
          vencimento: vencimento,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaExportacaoAlunos(modal);
            self.ngOnInit();
            await this.app.alert("Tudo certo!", data.message, "success");
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }

  async exportarAlunos(modal) {
    const self = this;

    const scheduledDate = moment(self.scheduledDate, "DD/MM/YYYY");
    const dueDate = self.dueDate ? moment(self.dueDate, "DD/MM/YYYY") : null;

    self.dueDateError = false;
    if (dueDate && dueDate.isBefore(scheduledDate)) {
      self.toastr.error(
        "Data de vencimento não pode ser anterior a data de agendamento",
        "Ops :("
      );
      self.dueDateError = true;
      return;
    }

    if (self.alunosSelecionadosParaExportar.length > 5) {
      const confirmacao = await self.app.confirm(
        "Tem certeza?",
        "Exportar mais de 5 alunos pode levar até 1 hora. Se desejar, você pode optar por exportar um aluno de cada vez. Deseja continuar com a exportação de todos os alunos?"
      );
      if (!confirmacao) {
        return;
      }
    }
    if (
      self.scheduledDate &&
      self.trainingToExport &&
      self.alunosSelecionadosParaExportar &&
      self.alunosSelecionadosParaExportar.length > 0
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("training/export/customer", {
          training: {
            ...self.trainingToExport,
            agendamento: moment(self.scheduledDate, "DD/MM/YYYY").toISOString(),
            vencimento: self.dueDate
              ? moment(self.dueDate, "DD/MM/YYYY").toISOString()
              : null,
          },
          relations: self.alunosSelecionadosParaExportar,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaExportacaoAlunos(modal);
            self.ngOnInit();
            await this.app.alert("Tudo certo!", data.message, "success");
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }

  duplicarTreino(training) {
    const self = this;
    self.app
      .confirm("Confirmação", "Você deseja duplicar este treino?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          training.isModel = false;
          training.isExperimental = false;
          self.apiCall
            .post("training/duplicate", { training: training })
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Treino duplicado com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }

  deletarTreino(training) {
    const self = this;
    self.app
      .confirm("Tem certeza?", "Você realmente deseja deletar este treino?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("training/personal/0/" + training.id)
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Treino deletado com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  async cancelaExportacaoProgramas(modal) {
    this.exportandoAlunos = false;
    this.programaDesafioSelecionado = null;
    modal.close("Close click");
  }
  async exportarTreinoPrograma(training, modal) {
    this.trainingToExport = training;
    this.programaDesafioSelecionado = null;
    this.modalService.open(modal, { centered: true });
  }
  async exportarProgramasDesafios(modal) {
    const self = this;
    if (self.trainingToExport && self.programaDesafioSelecionado) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("training/export/grouptraining", {
          training: self.trainingToExport,
          groupTraining: self.programaDesafioSelecionado,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaExportacaoAlunos(modal);
            self.ngOnInit();
            await this.app.alert(
              "Tudo certo!",
              "Treino exportado com sucesso",
              "success"
            );
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }

  async copymodeltrainings() {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.apiCall.post("training/copymodeltrainings").subscribe(async (data) => {
      self.spinner.hide();
      if (data.success) {
        self.user = await this.me.get(false).pipe(take(1)).toPromise();
        await this.app.alert(
          "Tudo certo!",
          "Treinos modelo importados com sucesso",
          "success"
        );
        self.ngOnInit();
      } else {
        await this.app.alert("Ops :(", data.message, "error");
      }
    });
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  viewMidia(urlToPlay, content) {
    this.urlToPlay = urlToPlay;
    if (urlToPlay.startsWith("https://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "https://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    } else if (urlToPlay.startsWith("http://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "http://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    }
    this.modalService.open(content, { centered: true, size: "lg" });
  }
}
