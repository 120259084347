<ng-container *ngIf="!showUserRegistrationModal && !showRegisterUserModal">
  <app-user-email-form
    [invite]="invite"
    [user]="user"
    (userCheckResult)="handleUserCheck($event)"
    (userInformation)="handleUserInformation($event)"
    (userEmail)="handleUserEmail($event)"
  >
  </app-user-email-form>
</ng-container>

<app-user-registration-modal
  *ngIf="showUserRegistrationModal"
  [invite]="invite"
  [user]="user"
  [email]="emailUser"
  (onRegister)="handleUserRegistration($event)"
>
</app-user-registration-modal>

<app-register-user-modal
  *ngIf="showRegisterUserModal"
  [product]="productData"
  [groupTraining]="groupTraining"
  [isNewUser]="isNewUser"
  [user]="user"
  [personal]="personal"
>
</app-register-user-modal>
