import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import Api from "app/helpers/api";

@Component({
  selector: "app-page-user",
  templateUrl: "./page-user.component.html",
  styleUrls: ["./page-user.component.scss"],
})
export class PageUserComponent implements OnInit {
  productId: string;
  apiCall = this.api.new().silent();
  invite: any = [];
  user: any;
  isRegistered: boolean = true;
  productData: any;
  groupTraining: any;
  showUserRegistrationModal = false;
  showRegisterUserModal = false;
  isNewUser: boolean = false;
  userData: any = null;
  emailUser: any;
  personal: any;
  constructor(private route: ActivatedRoute, private api: Api) {
    this.route.paramMap.subscribe((params) => {
      this.productId = params.get("productId");
    });
  }

  ngOnInit(): void {
    this.searchInvite();
  }

  searchInvite(): void {
    this.apiCall.get(`invite/hash/${this.productId}`).subscribe(
      (data) => {
        this.invite = data.return;
        this.user = this.invite.User;
        this.personal = this.invite.User;
        if (this.invite.User) {
          this.user = this.invite.User;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleUserRegistration(userData: any) {
    this.showUserRegistrationModal = false;
    this.productData = userData.product;
    this.groupTraining = userData.groupTraining;
    this.isRegistered = true;
    this.showRegisterUserModal = true;
    this.user = userData;
  }

  handleUserStatusChange(status: boolean) {
    this.isRegistered = status;
  }

  handleUserCheck(userExists: boolean) {
    if (userExists) {
      this.showUserRegistrationModal = false;
      this.showRegisterUserModal = true;
      this.isNewUser = false;
    } else {
      this.showUserRegistrationModal = true;
      this.showRegisterUserModal = false;
      this.isNewUser = true;
    }
  }

  handleUserInformation(userData: any) {
    this.userData = userData;
    this.productData = userData.product;
    this.groupTraining = userData.groupTraining;
    this.handleUserCheck(true);
  }

  handleUserEmail(userEmail: string) {
    this.emailUser = userEmail;
  }
}
