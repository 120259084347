// Angular Module
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { CalendarEvent } from "angular-calendar";

// Ngx Module
import { isSameDay, isSameMonth } from "ngx-bootstrap/chronos";
import { NgxSpinnerService } from "ngx-spinner";

// RXJS Module
import { Subject, Subscription, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  take,
} from "rxjs/operators";

// Internal Module
import * as moment from "moment";
import Swal from "sweetalert2";
import Api from "../../../../../helpers/api";
import App from "../../../../../helpers/app";
import Me from "../../../../../helpers/me";

// Ng Module
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { PlanStatusType } from "app/personal/pages/treinos/treinos-edit/treinos-edit.types";
import { CustomAdapter } from "app/shared/services/dateAdapter.service";
import { CustomDateParserFormatter } from "app/shared/services/dateParserFormatter.service";
import { DragulaService } from "ng2-dragula";
import { ClipboardService } from "ngx-clipboard";
import { ToastrService } from "ngx-toastr";
import { BetaTesterType } from "app/personal/pages/acompanhamento-execucoes/acompanhamento-execucoes.types";

const colors = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};

@Component({
  selector: "app-programa-detail",
  templateUrl: "./programa-detail.component.html",
  styleUrls: ["./programa-detail.component.scss"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class ProgramaDetailComponent implements OnInit {
  @ViewChild("modalContent") modalContent: TemplateRef<any>;

  view: string = "month";

  ctaEntrada = ["Saiba Mais", "Clique Aqui"];
  ctaSaida = [
    "Saiba Mais",
    "Clique Aqui",
    "Quero Condição Única",
    "Eu Quero",
    "Oportunidade Exclusiva",
  ];

  videoEntrada = {
    title: null,
    description: null,
    externalUrl: null,
    type: 1,
    video: null,
    status: false,
    cta: null,
  };
  videoEntradaFile;
  videoEntradaToShow;

  videoFinalizacao = {
    title: null,
    description: null,
    externalUrl: null,
    type: 2,
    video: null,
    status: false,
    cta: null,
  };
  videoFinalizacaoFile;
  videoFinalizacaoToShow;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  imgToShow;

  apiCall = this.api.new().silent();
  pageTr = 1;
  pageSize = 10;

  pageAl = 1;
  pageSizeAl = 10;

  pageEx = 1;
  pageSizeExec = 5;
  alunoProcurado;
  alunoOption = 1;
  groupsAlunos = [];
  grupoSelecionado;
  scheduledDate: string;
  dueDate: string;
  minScheduledDate = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date(),
  };
  checked = false;
  activeId = 1;
  user = null;
  inputTitle = null;
  needsApproval = null;
  relations = [];
  relationsExport = [];
  selectedRelations = [];
  GroupTrainingsCustomersOriginals = [];
  GroupTrainingsCustomers = [];
  GroupTrainingCustomersPendingOriginals = [];
  GroupTrainingCustomersPending = [];
  GroupTrainingCustomersReprovedOriginals = [];
  GroupTrainingCustomersReproved = [];
  orderedTrainings = [];
  customersSelecteds = [];
  capaSelecionada;
  capaSelecionadaFile;
  group;
  groupId;
  BAG = "bag-one";
  bufferSize = 50;
  loading = false;
  relationsSelect = [];
  input$ = new Subject<string>();
  subs = new Subscription();
  planStatus: PlanStatusType | null = null;
  isBetaTester = false;
  dueDateError = false;

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday());
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private dragulaService: DragulaService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private me: Me,
    private app: App,
    private toastr: ToastrService,
    private api: Api,
    private clipboardApi: ClipboardService,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {
    this.route.params.subscribe((params) => {
      if (params["id"]) {
        this.groupId = +params["id"];
      }
    });

    this.subs.add(
      dragulaService.drag(this.BAG).subscribe(({ el }) => {
        //this.removeClass(el, 'ex-moved');
      })
    );
    this.subs.add(
      dragulaService.drop(this.BAG).subscribe(({ el }) => {
        //this.addClass(el, 'ex-moved');
      })
    );
    this.subs.add(
      dragulaService.over(this.BAG).subscribe(({ el, container }) => {
        //this.addClass(container, 'ex-over');
      })
    );
    this.subs.add(
      dragulaService
        .out(this.BAG)
        .subscribe(({ el, container, name, source }) => {
          //this.removeClass(container, 'ex-over');
        })
    );

    this.me
      .get()
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
      });
  }

  async ngOnInit() {
    const self = this;
    this.resetVideos();
    self.group = null;
    self.groupsAlunos = [];
    self.scheduledDate = self.today;
    self.dueDate = "";
    self.relations = [];
    self.events = [];

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall
      .get<PlanStatusType>("seller/me/verify/subscription/iago")
      .subscribe((response) => {
        if (response.success) {
          this.planStatus = response.return;
        }
      });

    const groups = await self.apiCall
      .get("groupcustomers")
      .pipe(take(1))
      .toPromise();
    console.log("Grupo Alunos", groups.return);
    if (groups.return) self.groupsAlunos = groups.return;

    const rRelations = await self.apiCall
      .get("sellercustomer")
      .pipe(take(1))
      .toPromise();
    if (rRelations.return) {
      this.relations = rRelations.return.relations.slice();
      this.relationsExport = rRelations.return.relations;
    }

    if (!this.groupId) return;

    const response = await this.apiCall
      .get<BetaTesterType | null>("me/userparams/isBetaTester")
      .pipe(take(1))
      .toPromise();

    console.log("response", response);

    if (response && response.return && response.return.value === "true") {
      this.isBetaTester = true;
    } else {
      this.isBetaTester = false;
    }

    const g = await self.apiCall
      .get("grouptraining/detail/" + this.groupId)
      .pipe(take(1))
      .toPromise();

    if (g.return && g.return.Training) {
      if (g.return.GroupTrainingsVideos) {
        const entrada = g.return.GroupTrainingsVideos.find(
          (t) => t.type == "1"
        );
        if (entrada) {
          self.videoEntrada = {
            title: entrada.title,
            description: entrada.description ? entrada.description : null,
            externalUrl: entrada.externalUrl ? entrada.externalUrl : null,
            type: 1,
            video: entrada.video,
            status: entrada.status,
            cta: entrada.cta ? entrada.cta : null,
          };
          if (
            entrada.video &&
            entrada.video
              .toString()
              .toLowerCase()
              .startsWith("https://www.youtube.com")
          ) {
            self.videoEntradaToShow =
              this._sanitizer.bypassSecurityTrustResourceUrl(
                entrada.video.replace(
                  "https://www.youtube.com/watch?v=",
                  "https://www.youtube.com/embed/"
                )
              );
          } else if (entrada.video) {
            self.videoEntradaToShow = entrada.video;
          } else {
            self.videoEntradaToShow = null;
          }
        }
        const saida = g.return.GroupTrainingsVideos.find((t) => t.type == "2");
        if (saida) {
          self.videoFinalizacao = {
            title: saida.title,
            description: saida.description ? saida.description : null,
            externalUrl: saida.externalUrl ? saida.externalUrl : null,
            type: 2,
            video: saida.video,
            status: saida.status,
            cta: saida.cta ? saida.cta : null,
          };
          if (
            saida.video &&
            saida.video
              .toString()
              .toLowerCase()
              .startsWith("https://www.youtube.com")
          ) {
            self.videoFinalizacaoToShow =
              this._sanitizer.bypassSecurityTrustResourceUrl(
                saida.video.replace(
                  "https://www.youtube.com/watch?v=",
                  "https://www.youtube.com/embed/"
                )
              );
          } else if (saida.video) {
            self.videoFinalizacaoToShow = saida.video;
          } else {
            self.videoFinalizacaoToShow = null;
          }
        }
      }

      self.inputTitle = g.return.title;
      self.needsApproval = g.return.needsApproval;
    }
    if (g.return) self.group = g.return;

    if (
      g.return &&
      g.return.GroupTrainingsCustomers &&
      g.return.GroupTrainingsCustomers.length > 0
    ) {
      self.GroupTrainingsCustomers = g.return.GroupTrainingsCustomers.filter(
        (customer) => customer.SellerCustomer !== null
      );
      self.GroupTrainingsCustomersOriginals = [...self.GroupTrainingsCustomers];
      self.GroupTrainingCustomersPending = g.return.pendentes;
      self.GroupTrainingCustomersPendingOriginals = g.return.pendentes;
      self.GroupTrainingCustomersReproved = g.return.reprovados;
      self.GroupTrainingCustomersReprovedOriginals = g.return.reprovados;

      for (let g of self.GroupTrainingCustomersPending) {
        g.selected = false;
      }

      for (let r of g.return.ativos) {
        const found = self.relations.findIndex((x) => x.id == r.sellerCustomer);
        if (found > -1) {
          self.relations.splice(found, 1);
        }
      }
    }

    if (g.return && g.return.Training && g.return.Training.length > 0) {
      this.switchTreinos(true);
    }

    this.relationsSelect = this.relations.slice(0, 50);

    this.onSearch();

    self.spinner.hide();
  }

  fetchMore(term) {
    const self = this;
    const len = this.relationsSelect.length;
    let more = null;
    if (term == null) {
      more = this.relations.slice(len, this.bufferSize + len);
    } else {
      more = this.relations
        .filter((x) => x.Customer.name.includes(term))
        .slice(len, this.bufferSize + len);
    }
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.relationsSelect = this.relationsSelect.concat(more);
    }, 200);
  }

  onSearch() {
    const self = this;
    this.input$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((term) => {
          const values = this.relations.filter((x) =>
            x.Customer.name.includes(term)
          );
          return of(values);
        })
      )
      .subscribe((data) => {
        this.relationsSelect = data.slice(0, this.bufferSize);
      });
  }

  async openCalendar(modal) {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const g = await self.apiCall
      .get("trainingexecution/detail/" + self.groupId)
      .pipe(take(1))
      .toPromise();
    for (let t of g.return.Training) {
      if (t.TrainingExecution) {
        for (let e of t.TrainingExecution) {
          if (e.User && e.User.name) {
            let feedbackText = "";
            let observacao = "";
            if (e.rating != null && e.rating > 0) {
              let classificacao = self.getFeedback(e.rating);
              if (classificacao) {
                feedbackText = "Feedback de execução: " + classificacao;
              }

              if (e.observation != null) {
                observacao = "Observação do Treino: " + e.observation;
              }
            }
            self.events = [
              ...self.events,
              {
                id: t.id,
                title:
                  (e.User.name ? e.User.name : "") +
                  " executou o treino " +
                  t.name +
                  ".<br>" +
                  feedbackText +
                  ".<br>" +
                  observacao,
                start: moment(e.performedAt).toDate(),
                end: moment(e.performedAt).toDate(),
                color: {
                  primary: colors.blue.primary,
                  secondary: "white",
                },
                allDay: true,
                draggable: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
              },
            ];
          }
        }
      }
    }

    self.spinner.hide();
    this.modalService.open(modal, { centered: true, size: "xl" });
  }

  resetVideos() {
    this.videoEntrada = {
      title: null,
      description: null,
      externalUrl: null,
      type: 1,
      video: null,
      cta: null,
      status: false,
    };
    this.videoEntradaFile = null;
    this.videoEntradaToShow = null;

    this.videoFinalizacao = {
      title: null,
      description: null,
      externalUrl: null,
      type: 2,
      video: null,
      cta: null,
      status: false,
    };
    this.videoFinalizacaoFile = null;
    this.videoFinalizacaoToShow = null;
  }

  changeSelectedArray(event) {
    this.toggleStatus = false;
    const checked = event.target.checked;
    if (event.target.checked) {
      event.target.checked = true;
    } else {
      event.target.checked = false;
    }
  }

  toggleStatus = false;

  CheckAllOptions(event) {
    //const checked = event.target.checked;
    const checked = event.target.checked;
    if (this.toggleStatus == false) {
      this.GroupTrainingCustomersPending.forEach(
        (item) => (item.selected = true)
      );
      this.toggleStatus = true;
    } else {
      this.GroupTrainingCustomersPending.forEach(
        (item) => (item.selected = false)
      );
      this.toggleStatus = false;
    }
  }

  switchAprovacoes(status) {
    const self = this;
    if (status == true) {
      this.alunoOption = 1;
      self.GroupTrainingCustomersPending =
        self.GroupTrainingCustomersPendingOriginals;
    } else {
      this.alunoOption = 2;
      self.GroupTrainingCustomersPending =
        self.GroupTrainingCustomersReprovedOriginals;
    }
  }

  switchAprovacoesGeral() {
    const self = this;
    self.GroupTrainingCustomersPending =
      self.GroupTrainingCustomersPendingOriginals;
  }

  getFeedback(rating) {
    let retorno = "";
    if (rating == "1") {
      retorno = "Leve";
    } else if (rating == "2") {
      retorno = "Muito Leve";
    } else if (rating == "3") {
      retorno = "Ótimo";
    } else if (rating == "4") {
      retorno = "Pesado";
    } else if (rating == "5") {
      retorno = "Muito Pesado";
    }
    return retorno;
  }
  voltarParaProgramas() {
    if (this.group && this.group.type == "1") {
      this.router.navigate(["page/personal/programas/1"]);
    } else {
      this.router.navigate(["page/personal/desafios/2"]);
    }
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }
  linkConviteGerado = null;

  gerarLinkConvite(modal) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (this.isBetaTester) {
      const body = {
        productId: null,
        groupTrainingId: self.group.id,
      };

      self.apiCall.post("invite/", body).subscribe(async (data) => {
        await self.spinner.hide();
        self.openModalNormal(modal);
        if (data.success) {
          console.log(data.return);
          this.linkConviteGerado =
            data.return && data.return.inviteLink
              ? data.return.inviteLink
              : null;
          this.clipboardApi.copyFromContent(this.linkConviteGerado);
        } else {
          await self.app.alert("Ops :(", data.message, "error");
        }
      });
    } else {
      self.apiCall
        .get("grouptraining/invite/" + self.group.id)
        .subscribe(async (data) => {
          await self.spinner.hide();
          self.openModalNormal(modal);
          if (data.success) {
            this.linkConviteGerado = data.return;
            this.clipboardApi.copyFromContent(this.linkConviteGerado);
          } else {
            await self.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }
  copyInviteLink() {
    this.clipboardApi.copyFromContent(this.linkConviteGerado);
  }
  async atualizarVideo(isEntrada) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (isEntrada && this.videoEntradaFile) {
      const urlToPostVideo = await self.postFile(
        "mp4",
        this.videoEntradaFile.type
      );
      if (!urlToPostVideo.return) {
        self.spinner.hide();
        return;
      }
      var optionsVideo = {
        headers: {
          "Content-Type": this.videoEntradaFile.type,
          "x-amz-acl": "public-read",
        },
      };

      const uploadVideo = await self.api.http
        .put(urlToPostVideo.return, this.videoEntradaFile, optionsVideo)
        .toPromise();
      const locationVideo = urlToPostVideo.return.toString().split("?", 1)[0];
      self.videoEntrada.video = locationVideo;
    }

    if (!isEntrada && this.videoFinalizacaoFile) {
      const urlToPostVideo = await self.postFile(
        "mp4",
        this.videoFinalizacaoFile.type
      );
      if (!urlToPostVideo.return) {
        self.spinner.hide();
        return;
      }
      var optionsVideo = {
        headers: {
          "Content-Type": this.videoFinalizacaoFile.type,
          "x-amz-acl": "public-read",
        },
      };

      const uploadVideo = await self.api.http
        .put(urlToPostVideo.return, this.videoFinalizacaoFile, optionsVideo)
        .toPromise();
      const locationVideo = urlToPostVideo.return.toString().split("?", 1)[0];
      self.videoFinalizacao.video = locationVideo;
    }
    self.apiCall
      .put("grouptraining/video", {
        group: {
          id: self.group.id,
          groupVideo: isEntrada ? self.videoEntrada : self.videoFinalizacao,
        },
      })
      .subscribe(async (data) => {
        self.spinner.hide();
        await self.ngOnInit();
        if (data.success) {
          await this.app.alert(
            "Tudo certo!",
            "Vídeo atualizado com sucesso!",
            "success"
          );
          // this.toastr.success('Vídeo atualizado com sucesso!', 'Tudo Certo!');
        } else {
          await this.app.alert("Ops :(", data.message, "error");
        }
      });
  }
  onSelectAll() {
    this.selectedRelations = this.relations;
  }

  async toggleVideo(isEntrada, isUrl) {
    const self = this;
    if (isUrl) {
      let { value: file } = await Swal.fire({
        title: "Url do Vídeo do YouTube",
        input: "text",
        showCancelButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Salvar`,
        inputValidator: async (value) => {
          if (value && value.startsWith("https://youtu.be/")) {
            value = value.replace(
              "https://youtu.be/",
              "https://www.youtube.com/watch?v="
            );
          }
          if (!value) {
            return "Você precisa informar a url do YouTube!";
          }
          if (
            !value.startsWith("https://www.youtube.com/watch?") &&
            !value.startsWith("http://www.youtube.com/watch?")
          ) {
            return 'Você precisa informar uma url válida do Youtube! esta url deve começar com "https://www.youtube.com/watch?"';
          }
          if (isEntrada) {
            self.videoEntrada.video = value;
            if (value.startsWith("https://www.youtube.com/watch?")) {
              self.videoEntradaToShow =
                this._sanitizer.bypassSecurityTrustResourceUrl(
                  self.videoEntrada.video.replace(
                    "https://www.youtube.com/watch?v=",
                    "https://www.youtube.com/embed/"
                  )
                );
            } else {
              self.videoEntradaToShow =
                this._sanitizer.bypassSecurityTrustResourceUrl(
                  self.videoEntrada.video.replace(
                    "http://www.youtube.com/watch?v=",
                    "https://www.youtube.com/embed/"
                  )
                );
            }
            self.videoEntradaFile = null;
          } else {
            self.videoFinalizacao.video = value;
            if (value.startsWith("https://www.youtube.com/watch?")) {
              self.videoFinalizacaoToShow =
                this._sanitizer.bypassSecurityTrustResourceUrl(
                  self.videoFinalizacao.video.replace(
                    "https://www.youtube.com/watch?v=",
                    "https://www.youtube.com/embed/"
                  )
                );
            } else {
              self.videoFinalizacaoToShow =
                this._sanitizer.bypassSecurityTrustResourceUrl(
                  self.videoFinalizacao.video.replace(
                    "http://www.youtube.com/watch?v=",
                    "https://www.youtube.com/embed/"
                  )
                );
            }
            self.videoFinalizacaoFile = null;
          }
        },
      });
    } else {
      let { value: file } = await Swal.fire({
        title: "Vídeo do Exercício",
        input: "file",
        showCancelButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Salvar`,
        inputAttributes: {
          accept: "video/mp4",
          "aria-label": "Faça upload de um vídeo de até 15 segundos.",
        },
        inputValidator: async (value) => {
          if (!value) {
            return "Você precisa escolher um vídeo!";
          }
          // @ts-ignore
          let file: File = value;

          if (file.size > 30000000) {
            return "São permitidos vídeos de até 30MB!";
          }
          if (isEntrada) {
            self.videoEntradaFile = file;
            const base64 = await self.fileToBase64(file);
            if (base64) {
              self.videoEntradaToShow = base64;
              self.videoEntrada.video = base64;
            }
          } else {
            self.videoFinalizacaoFile = file;
            const base64 = await self.fileToBase64(file);
            if (base64) {
              self.videoFinalizacaoToShow = base64;
              self.videoFinalizacao.video = base64;
            }
          }
        },
      });
    }
  }
  async fileToBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async changeTitle() {
    const self = this;
    if (self.inputTitle) {
      self.group.title = self.inputTitle;
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .put("grouptraining", {
          group: self.group,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          await self.ngOnInit();
          if (data.success) {
            await this.app.alert(
              "Tudo certo!",
              "Programa atualizado com sucesso!",
              "success"
            );
            // this.toastr.success('Programa atualizado com sucesso!', 'Tudo Certo!');
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }
  async changePhoto() {
    const self = this;
    let { value: file } = await Swal.fire({
      title: "Imagem de Capa",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma imagem.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher uma imagem!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos imagens de até 30MB!";
        }
        if (
          file.type != "image/png" &&
          file.type != "image/jpg" &&
          file.type != "image/jpeg"
        ) {
          return "São permitidos apenas imagens!";
        }
        if (file && self.fileNameAndExt(file.name)[1]) {
          const urlToPost = await self.postFile(
            self.fileNameAndExt(file.name)[1],
            file.type
          );
          if (!urlToPost.return) {
            self.spinner.hide();
            return;
          }
          var options = {
            headers: { "Content-Type": file.type, "x-amz-acl": "public-read" },
          };

          const uploadedFile = await self.api.http
            .put(urlToPost.return, file, options)
            .toPromise();
          const locationFile = urlToPost.return.toString().split("?", 1)[0];
          self.group.photo = locationFile;
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .put("grouptraining", {
              group: self.group,
            })
            .subscribe(async (data) => {
              self.spinner.hide();
              await self.ngOnInit();
              if (data.success) {
                await this.app.alert(
                  "Tudo certo!",
                  "Programa atualizado com sucesso!",
                  "success"
                );
                // this.toastr.success('Programa atualizado com sucesso!', 'Tudo Certo!');
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      },
    });
  }
  handleEvent(action: string, event: CalendarEvent): void {}
  async postFile(extension, type) {
    return await new Promise<any>((res, rej) => {
      this.apiCall
        .post("auxiliar/file", { extension: extension, type: type })
        .subscribe(async (response) => {
          res(response);
        });
    });
  }
  fileNameAndExt(str) {
    var file = str.split("/").pop();
    return [
      file.substr(0, file.lastIndexOf(".")),
      file.substr(file.lastIndexOf(".") + 1, file.length),
    ];
  }
  closeDay() {
    this.activeDayIsOpen = false;
  }
  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }
  openModalImg(content, imgToShow) {
    this.imgToShow = imgToShow;
    this.modalService.open(content, { centered: true });
  }

  openTrainingType(modalInstance: any, callback: Function) {
    modalInstance.close();
    callback;
  }

  OpenPersonalGPTModal(content: any) {
    if (this.planStatus.plano_ativo && this.planStatus.plano_em_dia) {
      const beforeDismiss = async () => {
        return await this.app
          .confirm(
            "Atenção",
            "Você tem certeza que deseja sair? As alterações não salvas serão perdidas!"
          )
          .then((condition) => !!condition);
      };

      this.modalService.open(content, {
        centered: true,
        size: "xl",
        beforeDismiss,
      });
    } else {
      window.open("https://opersonaldigital.com.br/pv-iago/", "_blank");
    }
  }

  openCreationTypeModal(training: any = null, canEdit: boolean, content: any) {
    if (this.user) {
      this.modalService.open(content, { centered: true });
    } else {
      this.openTreino(training, canEdit);
    }
  }

  openTreino(training: any = null, canEdit: boolean) {
    const data = {
      training: training && training.id ? training.id : null,
      canEdit: canEdit,
      group: this.group.id,
    };

    const encodedData = btoa(JSON.stringify(data))
      .replace("==", "")
      .replace("=", "");

    if (this.user && training && training?.editorVersion === "iago_v1") {
      this.router.navigate([`/page/personal/treinos/${encodedData}/editar`]);
    } else {
      this.router.navigate(["/page/treino/details/" + encodedData]);
    }
  }

  lastOption = null;

  switchTreinos(status) {
    const self = this;
    if (status) {
      this.lastOption = 1;
      self.orderedTrainings = self.group.Training.filter(
        (t) => t.status == true
      );
      self.orderedTrainings.sort(function (a, b) {
        return a.order - b.order;
      });
    } else {
      this.lastOption = 2;
      self.orderedTrainings = self.group.Training.filter(
        (t) => t.status == false
      );
    }
  }

  tipos = [
    { id: 1, name: "Personalizado" },
    { id: 2, name: "Circuito" },
    { id: 3, name: "Intervalado" },
    { id: 4, name: "Avaliação" },
  ];

  getNameOfTypeTraing(type) {
    return this.tipos.find((t) => t.id == type).name;
  }
  reorderTreinos() {
    const self = this;
    if (this.lastOption == 1) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      for (let t of this.orderedTrainings) {
        t.order = this.orderedTrainings.indexOf(t) + 1;
      }
      self.apiCall
        .put("training/personal/0/order", {
          trainings: this.orderedTrainings,
          isgroup: true,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          await self.ngOnInit();
          if (data.success) {
            await this.app.alert(
              "Tudo certo!",
              "Treinos reordenados com sucesso!",
              "success"
            );
            // this.toastr.success('Treinos reordenados com sucesso!', 'Tudo Certo!');
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }
  switchTreino(event, training) {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (training && training.id && this.group && this.group.id) {
      self.apiCall
        .put("training/personal/0/status", {
          training: {
            id: training.id,
            group: this.group.id,
            status: event,
            vencimento: moment(training.vencimento).isAfter(moment())
              ? training.vencimento
              : null,
          },
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          await self.ngOnInit();
          if (data.success) {
            await this.app.alert(
              "Tudo certo!",
              event
                ? "Treino ativado com sucesso"
                : "Treino arquivado com sucesso",
              "success"
            );
            // this.toastr.success(event? 'Treino ativado com sucesso': 'Treino arquivado com sucesso' , 'Tudo Certo!');
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }

  switchNeedsApproval() {
    const self = this;

    self.app
      .confirm(
        "Tem certeza?",
        !this.needsApproval
          ? "Ao desativar a aprovação manual, todos os pedidos pendentes serão automaticamente aprovados?"
          : "Ao ativar a aprovação manual, todos os alunos que se cadastrarem através do link precisarão ser aprovadas?"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });

          let chamada;
          let body = {
            group: this.group.id,
            needsApproval: this.needsApproval,
          };

          chamada = self.apiCall.put("grouptraining/switchApproval", body);

          chamada.subscribe(async (data) => {
            await self.spinner.hide();
            if (data.success) {
              self.ngOnInit();
              await this.app.alert(
                "Tudo certo!",
                this.needsApproval
                  ? "Aprovação Manual ativada com sucesso!"
                  : "Aprovação Manual desativada com sucesso! ",
                "success"
              );
              // this.toastr.success(this.needsApproval ? 'Treino ativado com sucesso': 'Treino arquivado com sucesso' , 'Tudo Certo!');
            } else {
              await this.app.alert("Ops :(", data.message, "error");
              self.spinner.hide();
            }
          });
        }
      });
  }

  switchAlunoGroup(event, groupCustomer) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (groupCustomer && groupCustomer.id && this.group && this.group.id) {
      console.log(this.group);
      self.apiCall
        .put("grouptraining/customer", {
          groupCustomer: {
            id: groupCustomer.id,
            group: self.group.id,
            status: event,
          },
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          console.log("ALUNOS", data);
          await self.ngOnInit();
          if (data.success) {
            await this.app.alert(
              "Tudo certo!",
              event
                ? "Aluno ativado para este programa"
                : "Aluno inativado para este programa",
              "success"
            );
            // this.toastr.success(event? 'Aluno ativado para este programa': 'Aluno inativado para este programa' , 'Tudo Certo!');
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }
  openModalNormal(content) {
    this.grupoSelecionado = null;
    this.modalService.open(content, {
      centered: true,
      size: "lg",
      windowClass: "my-class",
    });
  }
  onSearchAluno() {
    if (this.alunoProcurado && this.alunoProcurado.length > 0) {
      this.GroupTrainingsCustomers =
        this.GroupTrainingsCustomersOriginals.filter((e) =>
          e.SellerCustomer.Customer.name
            .toString()
            .toLowerCase()
            .trim()
            .includes(this.alunoProcurado.toString().toLowerCase().trim())
        );
    } else {
      this.GroupTrainingsCustomers = this.GroupTrainingsCustomersOriginals;
    }
  }
  onSearchAlunoApproval() {
    if (this.alunoProcurado && this.alunoProcurado.length > 0) {
      if (this.alunoOption == 1) {
        this.GroupTrainingCustomersPending =
          this.GroupTrainingCustomersPendingOriginals.filter((e) =>
            e.SellerCustomer.Customer.name
              .toString()
              .toLowerCase()
              .trim()
              .includes(this.alunoProcurado.toString().toLowerCase().trim())
          );
      } else if (this.alunoOption == 2) {
        this.GroupTrainingCustomersPending =
          this.GroupTrainingCustomersReprovedOriginals.filter((e) =>
            e.SellerCustomer.Customer.name
              .toString()
              .toLowerCase()
              .trim()
              .includes(this.alunoProcurado.toString().toLowerCase().trim())
          );
      }
    } else {
      if (this.alunoOption == 1) {
        this.GroupTrainingCustomersPending =
          this.GroupTrainingCustomersPendingOriginals;
      } else if (this.alunoOption == 2) {
        this.GroupTrainingCustomersPending =
          this.GroupTrainingCustomersReprovedOriginals;
      }
    }
  }
  copiarAlunosGrupo() {
    if (!this.grupoSelecionado || !this.grupoSelecionado.CustomersGroupsUsers) {
      return;
    }
    for (let t of this.grupoSelecionado.CustomersGroupsUsers) {
      const found = this.relations.find((x) => x.id == t.relation);
      if (found) {
        let verify = null;
        if (this.selectedRelations && this.selectedRelations.length > 0) {
          this.selectedRelations.find((x) => x.id == found.id);
        }
        if (!verify) {
          this.selectedRelations.push(found);
        }
      }
    }
    this.selectedRelations = [...this.selectedRelations];
  }
  cadastrarAlunos(modal) {
    const self = this;

    if (
      this.selectedRelations &&
      this.selectedRelations.length > 0 &&
      this.group &&
      this.group.id
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("grouptraining/customer", {
          groupCustomer: {
            group: self.group.id,
            relations: self.selectedRelations,
          },
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          await self.ngOnInit();
          if (data.success) {
            modal.close("Close click");
            await this.app.alert(
              "Tudo certo!",
              "Alunos adicionados com sucesso!",
              "success"
            );
            // this.toastr.success('Alunos adicionados com sucesso!', 'Tudo Certo!');
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }
  duplicarTreino(training) {
    const self = this;
    self.app
      .confirm("Confirmação", "Você deseja duplicar este treino?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          training.isModel = false;
          training.isExperimental = false;
          training.group = this.group.id;
          self.apiCall
            .post("training/duplicate", { training: training })
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Treino duplicado com sucesso",
                  "success"
                );
                // this.toastr.success('Treino duplicado com sucesso', 'Tudo Certo!');
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  deletarTreino(training) {
    const self = this;
    self.app
      .confirm("Tem certeza?", "Você realmente deseja deletar este treino?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("training/personal/0/" + training.id + "/" + this.group.id)
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                await self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Treino deletado com sucesso",
                  "success"
                );
                // this.toastr.success('Treino deletado com sucesso', 'Tudo Certo!');
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  listaExercicios = [];
  getNameExercise(e) {
    if (!e.conjugado) {
      return "";
    }
    let retorno = "";
    const searching = this.listaExercicios.find((x) => x.id == e.conjugado);
    if (searching) {
      retorno = "Conjugado com " + searching.name;
    }
    return retorno;
  }
  typeTrainingInView = null;

  viewExercicios(training, content) {
    this.typeTrainingInView = training.type;

    this.listaExercicios = training.TrainingExercises.sort(function (a, b) {
      return a.order - b.order;
    });

    this.modalService.open(content, { centered: true, size: "lg" });
  }
  trainingToExport = null;
  exportandoAlunos = false;
  relationsFiltered = [];
  alunosSelecionadosParaExportar = [];

  async exportarTreinoAluno(training, modal) {
    this.grupoSelecionado = null;
    this.exportandoAlunos = true;
    this.trainingToExport = training;
    this.relationsFiltered = this.relationsExport;
    this.modalService.open(modal, { centered: true });
  }

  async cancelaExportacaoAlunos(modal) {
    this.grupoSelecionado = null;
    this.scheduledDate = this.today;
    this.dueDate = "";
    this.dueDateError = false;
    this.exportandoAlunos = false;
    this.trainingToExport = null;
    this.alunosSelecionadosParaExportar = [];
    modal.close("Close click");
  }

  async exportarAlunos(modal) {
    const self = this;

    const scheduledDate = moment(self.scheduledDate, "DD/MM/YYYY");
    const dueDate = self.dueDate ? moment(self.dueDate, "DD/MM/YYYY") : null;

    self.dueDateError = false;
    if (dueDate && dueDate.isBefore(scheduledDate)) {
      self.toastr.error(
        "Data de vencimento não pode ser anterior a data de agendamento",
        "Ops :("
      );
      self.dueDateError = true;
      return;
    }

    if (self.alunosSelecionadosParaExportar.length > 5) {
      const confirmacao = await self.app.confirm(
        "Tem certeza?",
        "Exportar mais de 5 alunos pode levar até 1 hora. Se desejar, você pode optar por exportar um aluno de cada vez. Deseja continuar com a exportação de todos os alunos?"
      );
      if (!confirmacao) {
        return;
      }
    }
    if (
      self.scheduledDate &&
      self.trainingToExport &&
      self.alunosSelecionadosParaExportar &&
      self.alunosSelecionadosParaExportar.length > 0
    ) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("training/export/customer", {
          training: {
            ...self.trainingToExport,
            agendamento: moment(self.scheduledDate, "DD/MM/YYYY").toISOString(),
            vencimento: self.dueDate
              ? moment(self.dueDate, "DD/MM/YYYY").toISOString()
              : null,
          },
          relations: self.alunosSelecionadosParaExportar,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            await this.cancelaExportacaoAlunos(modal);
            self.ngOnInit();
            await this.app.alert("Tudo certo!", data.message, "success");
            // this.toastr.success('Treino exportado com sucesso', 'Tudo Certo!');
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }

  copiarAlunosGrupoExpTreino() {
    if (!this.grupoSelecionado || !this.grupoSelecionado.CustomersGroupsUsers) {
      return;
    }
    for (let t of this.grupoSelecionado.CustomersGroupsUsers) {
      const found = this.relationsExport.find((x) => x.id == t.relation);
      if (found) {
        let verify = null;
        if (
          this.alunosSelecionadosParaExportar &&
          this.alunosSelecionadosParaExportar.length > 0
        ) {
          this.alunosSelecionadosParaExportar.find((x) => x.id == found.id);
        }
        if (!verify) {
          this.alunosSelecionadosParaExportar.push(found);
        }
      }
    }
    this.alunosSelecionadosParaExportar = [
      ...this.alunosSelecionadosParaExportar,
    ];
  }
  exportarTreinoBanco(training) {
    const self = this;
    self.app
      .confirm(
        "Confirmação",
        "Você deseja exportar este treino para o banco de treinos?"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .post("training/export/model", { training: training })
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Treino exportado com sucesso",
                  "success"
                );
                // this.toastr.success('Treino exportado com sucesso', 'Tudo Certo!');
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  aprovarAluno(aluno, acao) {
    const self = this;
    self.app
      .confirm(
        "Confirmação",
        "Você deseja alterar o status deste aluno no programa?"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });

          self.apiCall
            .post("training/duplicate", { customer: aluno })
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Treino duplicado com sucesso",
                  "success"
                );
                // this.toastr.success('Treino duplicado com sucesso', 'Tudo Certo!');
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  approvalCustomer(groupCustomer, status) {
    const self = this;
    self.app
      .confirm(
        "Confirmação",
        "Você deseja alterar o status deste aluno no programa?"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          if (
            groupCustomer &&
            groupCustomer.id &&
            this.group &&
            this.group.id
          ) {
            self.apiCall
              .put("grouptraining/approvalupdate", {
                groupCustomer: {
                  id: groupCustomer.id,
                  group: self.group.id,
                  approvalStatus: status,
                },
              })
              .subscribe(async (data) => {
                self.spinner.hide();
                if (data.success) {
                  await this.app.alert(
                    "Tudo certo!",
                    status == 1
                      ? "Aluno aprovado para este programa"
                      : "Aluno reprovado para este programa",
                    "success"
                  );
                  // this.toastr.success(status==1 ? 'Aluno aprovado para este programa': 'Aluno reprovado para este programa', 'Tudo Certo!');
                } else {
                  await this.app.alert("Ops :(", data.message, "error");
                }
                await self.ngOnInit();
              });
          }
        }
      });
  }
  approvalBulkCustomer(status, all) {
    const self = this;
    self.app
      .confirm(
        "Confirmação",
        "Você deseja alterar o status dos alunos selecionados no programa?"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          if (
            this.GroupTrainingCustomersPending &&
            this.group &&
            this.group.id
          ) {
            if (!all) {
              this.GroupTrainingCustomersPending =
                this.GroupTrainingCustomersPending.filter(
                  (x) => x.selected == true
                );
            }

            self.apiCall
              .put("grouptraining/bulkapproval", {
                groupCustomers: {
                  customers: this.GroupTrainingCustomersPending,
                  group: self.group.id,
                  approvalStatus: status,
                },
              })
              .subscribe(async (data) => {
                self.spinner.hide();
                if (data.success) {
                  await this.app.alert(
                    "Tudo certo!",
                    status == 1
                      ? "Alunos aprovados para este programa"
                      : "Alunos reprovados para este programa",
                    "success"
                  );
                  // this.toastr.success(status==1 ? 'Alunos aprovados para este programa': 'Alunos reprovados para este programa', 'Tudo Certo!');
                } else {
                  await this.app.alert("Ops :(", data.message, "error");
                }
                await self.ngOnInit();
              });
          }
        }
      });
  }

  verificaAlunosSelecionados() {
    if (this.GroupTrainingCustomersPending.find((x) => x.selected == true)) {
      return false;
    }
    return true;
  }
}
