import { InviteLinkCopiedModalComponent } from "../invite-link-copied-modal/invite-link-copied-modal.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import Api from "app/helpers/api";
import App from "app/helpers/app";
@Component({
  selector: "app-create-invite-link-modal",
  templateUrl: "./create-invite-link-modal.component.html",
  styleUrls: ["./create-invite-link-modal.component.scss"],
})
export class CreateInviteLinkModalComponent implements OnInit {
  products = [];
  productId = null;
  linkWhatsapp = null;
  groupTrainingId = null;
  linkConviteGerado = null;
  product: boolean = false;
  createLinkForm: FormGroup;
  apiCall = this.api.new().silent();
  apiInvites = this.api.new().silent(true);
  selectedProduct: any;
  constructor(
    private api: Api,
    private app: App,
    private router: Router,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private clipboardApi: ClipboardService
  ) {}

  ngOnInit(): void {
    this.listarProdutos();
  }

  listarProdutos() {
    const params = `status=true&offset=0&limit=50`;

    this.apiCall.get(`marketplace/products?${params}`).subscribe(
      (data) => {
        this.products = data.return.rows;
        console.log(this.products);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  gerarLinkConvite(product) {
    const self = this;
    const body = {
      productId: product?.id || null,
      groupTrainingId: this.groupTrainingId,
    };

    console.log(body);

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    self.apiInvites.post("invite", body).subscribe(async (data) => {
      await self.spinner.hide();

      if (data.success) {
        this.linkConviteGerado = data.return.inviteLink;
        console.log(this.linkConviteGerado);

        this.linkWhatsapp =
          "https://wa.me/?text=Tenho uma ótima notícia pra você! A partir de agora você receberá seus treinos através do meu app, basta clicar no link para baixar e realizar seu cadastro: " +
          encodeURIComponent(this.linkConviteGerado);

        this.activeModal.dismiss();

        this.clipboardApi.copyFromContent(this.linkConviteGerado);
        this.abrirModalLinkCopiado(this.linkConviteGerado);
      } else {
        await self.app.alert("Ops :(", data.message, "error");
      }
    });
  }

  extrairProductId(link: string): string {
    const parts = link.split("/");
    return parts[parts.length - 1];
  }

  abrirModalLinkCopiado(link: string) {
    const modalRef = this.modalService.open(InviteLinkCopiedModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.linkConviteGerado = link;
  }

  close() {
    this.activeModal.dismiss();
  }
}
