import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  SimpleChanges,
  HostListener,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import Api from "app/helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-user-registration-modal",
  templateUrl: "./user-registration-modal.component.html",
  styleUrls: ["./user-registration-modal.component.scss"],
})
export class UserRegistrationModalComponent implements OnInit, OnChanges {
  studentForm: FormGroup;
  productId: string;
  isSubmitting: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  private _user: any;
  private _email: any;
  apiCall = this.api.new().silent();

  @Input() invite: any;
  @Input()
  set user(value: any) {
    this._user = value;
  }
  get user(): any {
    return this._user;
  }
  @Input()
  set email(value: any) {
    this._email = value;
    if (this.studentForm) {
      this.studentForm.patchValue({ email: value });
    }
  }
  get email(): any {
    return this._email;
  }

  emailProviders = [
    "gmail.com",
    "hotmail.com",
    "outlook.com",
    "yahoo.com",
    "icloud.com",
  ];
  selectedIndex = -1;
  filteredProviders: string[] = [];
  showSuggestions = false;

  @Output() onRegister: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private api: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.productId = params.get("productId");
    });
  }
  ngOnInit(): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.studentForm = this.fb.group(
      {
        name: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        phone: ["", [Validators.required]],
        password: ["", [Validators.required, Validators.minLength(4)]],
        confirmPassword: ["", [Validators.required]],
      },
      { validator: this.passwordMatchValidator }
    );

    this.studentForm.statusChanges.subscribe((status) => {});

    if (this._email) {
      this.studentForm.patchValue({ email: this._email });
    }
    this.studentForm.get("email")?.valueChanges.subscribe((value) => {
      this.onEmailChange(value);
    });
    this.spinner.hide();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["email"] && this.studentForm) {
      this.studentForm.patchValue({ email: changes["email"].currentValue });
    }
  }

  togglePasswordVisibility(field: string) {
    if (field === "password") {
      this.showPassword = !this.showPassword;
    } else if (field === "confirmPassword") {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get("password")?.value;
    const confirmPassword = formGroup.get("confirmPassword")?.value;

    if (password !== confirmPassword) {
      formGroup.get("confirmPassword")?.setErrors({ passwordMismatch: true });
    } else {
      formGroup.get("confirmPassword")?.setErrors(null);
    }
  }
  onEmailChange(value: string) {
    if (value && value.includes("@")) {
      const domain = value.split("@")[1];
      if (domain) {
        this.filteredProviders = this.emailProviders.filter((provider) =>
          provider.startsWith(domain.toLowerCase())
        );
        this.showSuggestions = this.filteredProviders.length > 0;
        this.selectedIndex = -1; // Reseta a seleção quando muda o texto
        this.autoCorrectEmail(value);
      } else {
        this.filteredProviders = [...this.emailProviders];
        this.showSuggestions = true;
        this.selectedIndex = -1;
      }
    } else {
      this.showSuggestions = false;
      this.selectedIndex = -1;
    }
  }

  autoCorrectEmail(value: string) {
    const [username, domain] = value.split("@");
    if (!domain) return;

    const lowerDomain = domain.toLowerCase();
    const corrections = {
      gmali: "gmail.com",
      gamil: "gmail.com",
      hormail: "hotmail.com",
      hotmal: "hotmail.com",
      yahho: "yahoo.com",
    };

    if (corrections[lowerDomain]) {
      this.studentForm
        .get("email")
        ?.setValue(`${username}@${corrections[lowerDomain]}`, {
          emitEvent: false,
        });
    }
  }

  selectSuggestion(suggestion: string) {
    const username = this.studentForm.get("email")?.value.split("@")[0];
    this.studentForm.get("email")?.setValue(`${username}@${suggestion}`);
    this.showSuggestions = false;
    this.selectedIndex = -1;
  }

  // Manipulação de eventos de teclado
  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.showSuggestions || this.filteredProviders.length === 0) return;

    switch (event.key) {
      case "ArrowDown":
        event.preventDefault();
        if (this.selectedIndex < this.filteredProviders.length - 1) {
          this.selectedIndex++;
        }
        break;

      case "ArrowUp":
        event.preventDefault();
        if (this.selectedIndex > 0) {
          this.selectedIndex--;
        }
        break;

      case "Enter":
        event.preventDefault();
        if (this.selectedIndex >= 0) {
          this.selectSuggestion(this.filteredProviders[this.selectedIndex]);
        }
        break;

      case "Escape":
        this.showSuggestions = false;
        this.selectedIndex = -1;
        break;
    }
  }
  onSubmit() {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const formData = {
      name: this.studentForm.get("name")?.value,
      email: this.studentForm.get("email")?.value,
      phone: this.studentForm.get("phone")?.value,
      password: this.studentForm.get("password")?.value,
      invite: this.invite?.hash,
    };

    this.apiCall
      .post("/invite/link/sellercustomer/customer", formData)
      .subscribe((response) => {
        if (response.code === 200) {
          this.toastr.success("Cadastro realizado com sucesso!");
          this.onRegister.emit(response.return);
        } else {
          this.toastr.error(response?.message || "Erro inesperado", "Ops!");
        }
        this.spinner.hide();
      });
  }
  onCancel(): void {
    this.studentForm.reset();
  }
}
