<div class="modal-container" *ngIf="invite">
  <div class="modal-container" *ngIf="invite">
    <div class="modal-content">
      <img
        [src]="user.photo_url"
        alt="Personal Trainer"
        class="trainer-image"
      />
      <h2>{{ user.name }}</h2>
      <h6 style="text-align: center">Você recebeu um convite do personal!</h6>
      <p>Por favor, digite seu email para validarmos o acesso:</p>
      <div>
        <div class="form-group">
          <label>Email</label>
          <input
            type="email"
            placeholder="Email"
            class="form-control"
            [(ngModel)]="email"
          />
        </div>

        <div class="buttons">
          <button
            type="submit"
            (click)="onSubmit()"
            class="btn btn-primary"
            [disabled]="this.email == ''"
          >
            Verificar Email
          </button>
          <button type="button" class="btn btn-secondary" (click)="onCancel()">
            Cancelar
          </button>
        </div>
      </div>
    </div>

    <div class="modal-container" *ngIf="showNotFoundModal">
      <div class="modal-content">
        <h2>Ops! Não encontramos uma conta neste e-mail!</h2>
        <p>Mas não se preocupe, podemos criar sua conta rapidinho!</p>

        <div class="buttons">
          <button class="btn btn-primary" (click)="goToSigin()">
            Criar Conta
          </button>
          <button class="btn btn-secondary" (click)="closoModal()">
            Tentar outro e-mail
          </button>
        </div>
      </div>
    </div>
  </div>

  <ngx-spinner></ngx-spinner>
</div>
