import { Component, HostListener, Input } from "@angular/core";

@Component({
  selector: "app-register-user-modal",
  templateUrl: "./register-user-modal.component.html",
  styleUrls: ["./register-user-modal.component.scss"],
})
export class RegisterUserModalComponent {
  showProductOffer = true;

  private _product: any;
  private _groupTraining: any;
  private _isNewUser: boolean = false;
  private _user: any;
  private _personal: any;

  @Input()
  set product(value: any) {
    this._product = value;
  }
  get product(): any {
    return this._product;
  }

  @Input()
  set groupTraining(value: any) {
    this._groupTraining = value;
  }
  get groupTraining(): any {
    return this._groupTraining;
  }

  @Input()
  set isNewUser(value: boolean) {
    this._isNewUser = value;
  }
  get isNewUser(): boolean {
    return this._isNewUser;
  }

  @Input()
  set user(value: any) {
    this._user = value;
  }
  get user(): any {
    return this._user;
  }

  @Input()
  set personal(value: any) {
    this._personal = value;
  }

  get personal(): any {
    return this._personal;
  }

  constructor() {}

  isMobile: boolean = window.innerWidth < 768;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.isMobile = window.innerWidth < 768;
  }
}
