<div class="modal-container" *ngIf="!product && !groupTraining">
  <div class="modal-content">
    <p class="text-center success-message">
      {{
        isNewUser
          ? "Uhu! Cadastro Finalizado!"
          : "Uhu! Encontramos seu Cadastro!"
      }}
    </p>
    <p class="download-text">
      Baixe o app “O Personal Digital” para ter acesso aos seus treinos:
    </p>

    <!-- Botões de Download -->
    <div class="download-buttons">
      <a
        href="https://play.google.com/store/apps/details?id=br.com.personaldigital"
        class="btn-store-download"
      >
        <img src="../../assets/img/play_store_logo.svg" alt="Play Store" />
        Google Play
      </a>
      <a
        href="https://apps.apple.com/br/app/o-personal-digital/id1532310171"
        class="btn-store-download-app"
      >
        <img src="../../assets/img/apple_logo.svg" alt="App Store" />
        App Store
      </a>
    </div>

    <p class="cadastro-login">
      Faça login com o email <strong>{{ user?.email || "" }}</strong> e a senha
      escolhida no cadastro!
    </p>
  </div>
</div>

<!-- Modal Principal -->
<div class="modal-container" *ngIf="product">
  <div class="modal-content">
    <p class="text-center success-message">
      {{
        isNewUser
          ? "Uhu! Cadastro Finalizado!"
          : "Uhu! Encontramos seu Cadastro!"
      }}
    </p>
    <p class="download-text">
      Baixe o app “O Personal Digital” para ter acesso aos seus treinos:
    </p>

    <!-- Botões de Download -->
    <div class="download-buttons">
      <a
        href="https://play.google.com/store/apps/details?id=br.com.personaldigital"
        class="btn-store-download"
      >
        <img src="../../assets/img/play_store_logo.svg" alt="Play Store" />
        Google Play
      </a>
      <a
        href="https://apps.apple.com/br/app/o-personal-digital/id1532310171"
        class="btn-store-download-app"
      >
        <img src="../../assets/img/apple_logo.svg" alt="App Store" />
        App Store
      </a>
    </div>

    <p class="cadastro-login">
      Faça login com o email <strong>{{ user?.email || "" }}</strong> e a senha
      escolhida no cadastro!
    </p>
  </div>
</div>

<!-- Layout para TREINO / DESAFIO -->
<div class="modal-container" *ngIf="groupTraining">
  <div class="modal-content">
    <p class="text-center success-message">
      {{
        isNewUser ? "Uhu! Cadastro Finalizado!" : "Encontramos seu cadastro!"
      }}
    </p>
    <p class="download-text">
      Abra o app “O Personal Digital” no seu celular para ter acesso ao desafio!
    </p>

    <div
      class="group-offer"
      style="
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="image-container">
        <img
          [src]="groupTraining.photo"
          alt="Group Training Image"
          class="product-img img-fluid"
        />
      </div>

      <div class="training-info">
        <p class="training-title ml-2" style="color: black">
          {{ groupTraining.title }}
        </p>
      </div>
    </div>

    <div class="download-buttons mt-4">
      <a
        href="https://play.google.com/store/apps/details?id=br.com.personaldigital"
        target="_blank"
        rel="noopener noreferrer"
        class="btn btn-groupTraining"
      >
        <img src="../../assets/img/play_store_logo.svg" alt="Play Store" />
        <span>Acessar pelo Android</span>
      </a>

      <a
        href="https://apps.apple.com/br/app/o-personal-digital/id1532310171"
        target="_blank"
        rel="noopener noreferrer"
        class="btn btn-groupTraining-app"
      >
        <img src="../../assets/img/apple_logo.svg" alt="App Store" />
        <span>Acessar pelo iOS</span>
      </a>
    </div>

    <p class="cadastro-login">
      <i class="ft-alert-circle"></i>
      Faça login com o email <strong>{{ user?.email || "seu email" }}</strong> e
      a senha escolhida no cadastro!
    </p>
  </div>
</div>

<!-- Layout para PRODUTOS - DESKTOP -->
<div *ngIf="!isMobile && product" class="product-card container mt-4">
  <p class="text-start font-weight-500">
    Confira o que {{ personal.name }} recomendou para você:
  </p>

  <div class="product-offer">
    <div class="products-image col-auto" *ngIf="product.cover">
      <img [src]="product.cover" alt="Product Image" />
    </div>
    <div class="products-details">
      <p class="font-weight-bold title">{{ product.name }}</p>
      <p>{{ product.description }}</p>
      <div class="price">
        <p class="products-price mr-4">
          {{ product.Plans[0]?.value_cents / 100 | currency : "BRL" }}
        </p>
        <div class="products-button">
          <button>Comprar</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Layout para PRODUTOS - MOBILE -->
<div *ngIf="isMobile && product" class="product-card container mt-4">
  <div class="product-card">
    <p class="text-start font-weight-500">Recomendação de Personal Fulano:</p>
    <div class="product-offer">
      <div class="image-container" *ngIf="product.cover">
        <img [src]="product.cover" alt="Product Image" class="product-img" />
      </div>
      <div class="product-info">
        <p class="product-title">{{ product.name }}</p>
        <p class="price">R$ {{ product.Plans[0]?.value_cents / 100 }}</p>
        <p class="product-description">{{ product.description }}</p>
        <button class="buy-button">Comprar</button>
      </div>
    </div>
  </div>
</div>
