<div class="modal-header">
  <div class="card">
    <div class="card-header text-center pt-0">
      <div class="text-right text-sm-right">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="close()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <h3 class="modal-title custom-add-title">
        <i class="ft-check" style="color: #2dbf16"></i> Link de Convite Copiado
      </h3>
    </div>
    <div class="card-body">
      <div class="row mb-2 mt-2 justify-content-center">
        <div class="col-12">
          <p class="text-center">
            Agora é só compartilhar com seus alunos. Compartilhe via:
          </p>
        </div>
        <div class="row">
          <div class="col-6 text-center">
            <a
              class="btn btn-light rounded-circle mb-2"
              [href]="linkWhatsapp"
              target="_blank"
              style="
                background-color: #f0f0f0;
                border: 2px solid #a9a9a9;
                width: 50px;
                height: 50px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
              "
            >
              <i class="fa fa-whatsapp" style="color: #a9a9a9"></i>
            </a>
          </div>
          <div class="col-6 text-center">
            <a
              class="btn btn-light rounded-circle mb-2"
              (click)="toggleEmailInput()"
              style="
                background-color: #f0f0f0;
                border: 2px solid #a9a9a9;
                width: 50px;
                height: 50px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
              "
            >
              <i class="fa fa-envelope" style="color: #a9a9a9"></i>
            </a>
          </div>
        </div>
        <div class="col-12 text-center mt-3">
          <label style="display: flex">Email</label>
          <input
            type="email"
            [(ngModel)]="emailToInvite"
            class="form-control mb-2"
            placeholder="Digite o e-mail"
          />
          <button class="btn btn-primary" (click)="sendLinkByEmail()">
            Enviar link
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
