import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import Api from "app/helpers/api";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-user-email-form",
  templateUrl: "./user-email-form.component.html",
  styleUrls: ["./user-email-form.component.scss"],
})
export class UserEmailFormComponent implements OnInit {
  @Input() invite: any;
  @Input() user: any;
  @Output() userInformation: EventEmitter<any> = new EventEmitter<any>();
  @Output() userCheckResult = new EventEmitter<boolean>();
  @Output() userEmail: EventEmitter<string> = new EventEmitter<string>();
  email: string = "";
  apiCall = this.api.new().silent();
  showNotFoundModal: boolean = false;
  userRegister: any;
  constructor(
    private route: ActivatedRoute,
    private api: Api,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const formData = {
      email: this.email,
      invite: this.invite.hash,
    };

    this.apiCall.post("invite/check-email", formData).subscribe(
      (response) => {
        if (response.code == 200) {
          if (response.return.existingUser === false) {
            this.showNotFoundModal = true;
            this.spinner.hide();
          } else {
            this.userRegister = response.return;
            this.userInformation.emit(response.return);
            this.userCheckResult.emit(true);
            this.spinner.hide();
          }
        }
      },
      (error) => {
        console.error("Erro ao verificar e-mail:", error);
      }
    );
  }

  onCancel(): void {
    this.email = "";
  }

  goToSigin() {
    this.showNotFoundModal = false;
    this.userCheckResult.emit(false);
    this.userEmail.emit(this.email);
  }

  closoModal() {
    this.showNotFoundModal = false;
  }
}
