<div class="modal-header">
  <h5 class="modal-title">Criar Link de Convite</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<h6 style="text-align: center" class="mt-2">
  Novidade! Agora você pode adicionar um produto ao link de convite”
</h6>
<div class="modal-body">
  <label>Selecione um produto/serviço:</label>
  <select [(ngModel)]="selectedProduct">
    <option *ngFor="let product of products" [ngValue]="product">
      {{ product.name }}
    </option>
  </select>
  <div class="row ml-2">
    <input type="checkbox" id="noProduct" formControlName="noProduct" />
    <label style="font-size: 12px; margin-top: 10px; margin-left: 5px"
      >Não adicionar produto</label
    >
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" (click)="close()">Cancelar</button>
  <button class="btn btn-primary" (click)="gerarLinkConvite(selectedProduct)">
    Criar Link
  </button>
</div>
